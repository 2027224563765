import React from "react";
import {
  Typography,
  Button,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit, faEye } from "@fortawesome/free-solid-svg-icons";

export const CategoryColumn = ({ handleView, handleEdit, handleDelete }) => [
  {
    name: "No.",
    selector: (_, index) => index + 1,
    sortable: true,
  },
  {
    name: "ID",
    selector: row => row._id,
    sortable: true,
  },
  {
    name: "Name",
    selector: row => row.name,
    sortable: true,
  },
  {
    name: "Icon",
    cell: (row) => <img src={row.photo} alt="Category Icon" style={{ width: '50px', height: '50px' }} />
  },
  {
    name: "Status",
    cell: (row) => row.status ? <Typography variant="primary" >Active </Typography>: <Typography variant="primary" > Deactivated </Typography> 
  },
  {
    name: "Created At",
    selector: row => row.createdAt,
    sortable: true,
  },
  {
    name: "Display Type",
    selector: row => row.displayType,
    sortable: true,
  },
  {
    name: "Actions",
    sortable: false,
    cell: (row) => (
      <div style={{
        display: "flex",
      }}>
        <Button
          variant="danger"
          data-tag="allowRowEvents"
          data-action="delete"
          onClick={() => handleDelete(row._id)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
        <Button variant="primary" onClick={() => handleEdit(row._id)}>
          <FontAwesomeIcon icon={faEdit} />
        </Button>
        <Button variant="primary" onClick={() => handleView(row._id)}>
          <FontAwesomeIcon icon={faEye} />
        </Button>
      </div>
    ),
  },
];

import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Button,
} from "@mui/material";
import GetApi from "../../utils/GETAPI";
// import "./DangerReports.css";
import { truncateDescription } from "../../helpers/TruncateText";
import { useNavigate } from "react-router-dom";
import { ADMIN, DANGER_VIEW } from "../../routes/routeConfig";
import BottomTabs from "../../components/Field/Drawer";
import "../Users/UserList/Users.css";
import moment from "moment";
import DataTable from "react-data-table-component";
import { DangerColumn } from "./DangerColumn";
import { CSVLink, CSVDownload } from "react-csv";
import PostAPI from "../../utils/POSTAPI";
import { flatten } from 'lodash';

const DangerList = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [filterUser, setFilterUser] = useState([]);
  const [change, setChange] = useState("");
  const fileName = "DangerData";

  useEffect(() => {
    GetAllDangerReports();
  }, [change]);

  const GetAllDangerReports = () => {
    GetApi.GetAllDangerReports((response) => {
      if (!response.status) {
        setData([]);
      } else if (response.status) {
        setData(response.data);
        setFilterUser(response.data);
      } else {
        setData([]);
      }
    });
  };

  const handleChangeStatus = (id, status) => {
    let formdata = {
      _id: id,
      validate: status,
    };
    PostAPI.ValidateDangerStatus(formdata, (response) => {
      if (!response.status) {
        setData([]);
      } else if (response.status) {
        setData(response.data);
        setChange("1");
      } else {
        setData([]);
      }
    });
  };

  const handleDangerView = (id) => {
    navigate(`/${ADMIN}/${DANGER_VIEW}`, { state: { id } });
  };

  const handleDangerDelete = (id) => {
    GetApi.DeleteDangerLocation(id, (response) => {
      if (!response.status) {
        setData([]);
      } else if (response.status) {
        setData(response.data);
        setChange("2");
      } else {
        setData([]);
      }
    });
  };


  useEffect(() => {
    const result = data.filter((deceased) => {
      return (
        (deceased.name &&
          deceased.name.toLowerCase().match(search.toLowerCase())) ||
        Object.keys(deceased.category).some(
          (key) =>
            deceased.category[key] === true &&
            key.toLowerCase().match(search.toLowerCase())
        ) ||
        (deceased.userId &&
          deceased.userId
            .toLowerCase()
            .match(search.toLocaleLowerCase())) ||
        (deceased.mineLocationName &&
          deceased.mineLocationName
            .toLowerCase()
            .match(search.toLocaleLowerCase()))
      );
    });

    setFilterUser(result);
  }, [search]);

  const columns = DangerColumn({
    handleChangeStatus,
    handleDangerDelete,
    handleDangerView,
  });

    // Flattening the nested object
    const flattenedData = filterUser.map(item => ({
    ...item,
    category: item.category
    }));

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "30px",
        padding: "30px",
      }}
    >
      <BottomTabs />
      <ToastContainer />
      <DataTable
        title="Danger List"
        columns={columns}
        data={filterUser}
        pagination
        highlightOnHover
        fixedHeader
        fixedHeaderScrollHeight="500px"
        selectableRows
        selectableRowsHighlight
        clearSelectedRows
        actions={
          <Button sx={{ fontSize: "12px" ,bgcolor: '#d3200a' ,color: 'red' ,'&:hover': { bgcolor: 'green' }}}>
            <CSVLink data={flattenedData} filename={`${fileName}.csv`} style={{color: 'white' ,textDecoration: 'unset' }} >
              Export
            </CSVLink>
          </Button>
        }
        subHeader
        subHeaderComponent={
          <input
            type="text"
            placeholder="Search"
            className="w-25 form-control"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        }
        subHeaderAlign="right"
      />
    </Box>
  );
};

export default DangerList;

import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Button,
} from "@mui/material";
import GetApi from "../../utils/GETAPI";
import "./DangerReports.css";
import { truncateDescription } from "../../helpers/TruncateText";
import { useNavigate } from "react-router-dom";
import { ADMIN, DANGER_REPORT_DETAILS, LOGIN } from "../../routes/routeConfig";
import BottomTabs from "../../components/Field/Drawer";

function DangerReports() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getAllDangerReports();
  }, []);

  const getAllDangerReports = () => {
    GetApi.GetAllDangerReports((response) => {
      if (!response.status) {
        setData([]);
      } else if (response.status) {
        setData(response.data);
      } else {
        setData([]);
      }
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "30px",
        padding: "30px",
      }}
    >
      <BottomTabs />
      <Typography variant="h5">Danger Reports</Typography>
      <List style={{ width: "100%", maxWidth: 1000 }}>
        {data.length == 0 && (
          <Typography variant="subtitle1">
            <span style={{ fontWeight: "bold", fontSize: 30 }}>No Data</span>
          </Typography>
        )}
        {data.map((obj, index) => (
          <div
            onClick={() =>
              navigate(`/${ADMIN}/${DANGER_REPORT_DETAILS}`, { state: { obj } })
            }
            key={index}
            className="listContainer"
          >
            <ListItem>
              <ListItemAvatar>
                <img className="image" src={obj.photo} />
              </ListItemAvatar>
              <Box
                marginLeft={"20px"}
                gap="5px"
                display="flex"
                flexDirection="column"
              >
                <Typography variant="subtitle1">
                  <span style={{ fontWeight: "bold" }}>Category:</span>{" "}
                  {obj.category}
                  {/* {obj.category.UnexplodedMinesandBombs &&
                    "Unexploded Mines & Bombs"}
                  {obj.category.UnstableStructuresandBuildings &&
                    "Unstable Structures & Buildings"}
                  {obj.category.RoadBlocks && "Road Blocks"}
                  {obj.category.AttackersSpotted && "Attacker(s) Spotted"}
                  {obj.category.Floods && "Floods"}
                  {obj.category.Fire && "Fire"}
                  {obj.category.Earthquake && "Earthquake"}
                  {obj.category.Others && "Others"} */}
                </Typography>
                <Typography variant="body2">
                  <span style={{ fontWeight: "bold" }}>Description:</span>{" "}
                  {truncateDescription(obj.description)}
                </Typography>
                <Typography variant="body2">
                  <span style={{ fontWeight: "bold" }}>User ID:</span>{" "}
                  {obj.userId}
                </Typography>
                <Typography variant="body2">
                  <span style={{ fontWeight: "bold" }}>User Email:</span>{" "}
                  {obj.email}
                </Typography>
                <Typography variant="body2">
                  <span style={{ fontWeight: "bold" }}>Approved:</span>{" "}
                  {JSON.stringify(obj.adminApproved)}
                </Typography>
              </Box>
            </ListItem>
          </div>
        ))}
      </List>
    </Box>
  );
}

export default DangerReports;

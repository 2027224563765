import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import { googleLogout } from "@react-oauth/google";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
// import { PROFILE } from "navigation/routeConfig";
// import { clientID } from "utils/GoogleClientID";
// import { GoogleLogout } from "react-google-login";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { MuiTelInput } from "mui-tel-input";
import PostAPI from "../../utils/POSTAPI";
import GetApi from "../../utils/GETAPI";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Typography,
    Card,
    CardMedia,
    CardContent,
    Button,
    Grid,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    AppBar,
    Toolbar,
    IconButton,
    Stack,
    TextField
  } from "@mui/material";
  import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function SettingsIndex() {

  let navigate = useNavigate();
  const { t } = useTranslation();
  const [type, setType] = useState();
  const [name, setName] = useState();
  const [phone, setPhone] = useState("");
  const [whatsApp, setWhatsApp] = useState("");
  const [telegram, setTelegram] = useState("");
  const [fb, setFb] = useState("");
  const [line, setLine] = useState("");
  const [viber, setViber] = useState("");
  const [weChat, setWeChat] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [filename, setFilename] = useState("");
  const [open, setOpen] = useState(false);
  const [imgSrc, setImgSrc] = useState(null);
  const [change, setChange] = useState("");

  const location = useLocation();

  useEffect(() => {
    const { id } = location.state;
    Profile(id);
  }, [change]);

  const Profile = (id) => {
    GetApi.GetProfile(id,(response) => {
      if (!response.status) {
        setImagePreview("");
        setLine("");
        setTelegram("");
        setFb("");
        setViber("");
        setWhatsApp("");
        setPhone("");
        setWeChat("");
      } else if (response.status) {
        setName(response.data.name);
        setImgSrc(response.data.picture || "")
        setImagePreview(response.data.picture || "");
        setLine(response.data.lineInfo || "");
        setTelegram(response.data.telegramInfo || "");
        setFb(response.data.facebookInfo || "");
        setViber(response.data.viberInfo || "");
        setWhatsApp(response.data.whatsappInfo || "");
        setPhone(response.data.phoneInfo || "");
        setWeChat(response.data.weChatInfo || "");
      } else {
        setImagePreview("");
        setLine("");
        setTelegram("");
        setFb("");
        setViber("");
        setWhatsApp("");
        setPhone("");
        setWeChat("");
      }
    });
  };

  const editprofile = () => {

    const { id } = location.state;
   
    const updatedData = {
        _id: id,
        name:name,
        photo:imgSrc.name,
        telegramInfo:telegram,
        whatsappInfo:whatsApp,
        phoneInfo:phone,
        facebookInfo:fb,
        lineInfo:line,
        viberInfo:viber,
        weChatInfo:weChat,
      };
    PostAPI.EditProfile(updatedData, (response) => {
      if (!response.status) {
        alert("Something went wrong");
        // window.location.reload();
      } else if (response.status) {
        alert("Profile updated succesfully");
        setChange("1");
      } else {
        alert("Something went wrong");
        // window.location.reload();
      }
    });
  };

  const handleChange = (newValue) => {
    setPhone(newValue);
  };

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  // const handleApprove = () => {
  //   const { id } = location.state;
  //   let formdata = {
  //     _id: id,
  //     status: true,
  //   };
  //   PostAPI.StatusUpdate(formdata, (response) => {
  //     if (!response.status) {
  //       alert("Something went wrong");
  //     } else if (response.status) {
  //       setChange("1");
  //     } else {
  //       alert("Something went wrong");
  //     }
  //   });
  //   handleClose();
  // };

  // const handleReject = () => {
  //   const { id } = location.state;
  //   let formdata = {
  //     _id: id,
  //     status: false,
  //   };
  //   PostAPI.StatusUpdate(formdata, (response) => {
  //     if (!response.status) {
  //       alert("Something went wrong");
  //     } else if (response.status) {
  //       setChange("2");
  //     } else {
  //       alert("Something went wrong");
  //     }
  //   });
  //   handleClose();
  // };

  const handleImage = async (e) => {
    e.preventDefault();
    let image = URL.createObjectURL(e.target.files[0]);
    setFilename(e.target.files[0].name);
    setImagePreview(image);
    setImgSrc(e.target.files[0]);
  };

  useEffect(() => {
    const type = localStorage.getItem("loginType");
    setType(type);
  }, []);

  const logOut = () => {
    localStorage.clear();
    navigate("/login", { replace: true });
  };

  return (
    <div style={{ paddingBottom: "80px" }}>
        <AppBar style={{ backgroundColor: "white" }} position="static">
            <Toolbar>
              <IconButton
                onClick={() => navigate(-1)}
                edge="start"
                color="inherit"
                aria-label="back"
              >
                <ArrowBackIcon style={{ color: "black" }} />
              </IconButton>
            </Toolbar>
        </AppBar>
      <Container maxWidth="lg">
        <Container maxWidth="lg">
        <ToastContainer />
          <Typography
            color="#D22108"
            fontWeight="bold"
            component="h5"
            variant="subtitle1"
            align="center"
            gutterBottom
            marginTop={2}
            marginBottom={1}
            fontSize={35}
          >
            Edit Profile
          </Typography>
          <Typography
            color="#D22108"
            fontWeight="500"
            component="h5"
            variant="subtitle1"
            align="left"
            gutterBottom
            marginTop={2}
            marginBottom={1}
          >
            Profile Picture
            {/* {t("maps.profile_picture")} */}
          </Typography>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {imagePreview ? (
              <div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    alt={filename}
                    src={imagePreview}
                    style={{
                      borderRadius: "90px",
                      height: "150px",
                      width: "150px",
                      marginBottom: "20px",
                    }}
                  />
                </div>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={2}
                >
                  <Button
                    variant="contained"
                    component="label"
                    startIcon={<PhotoCamera />}
                  >
                    Reupload
                    {/* {t("maps.reupload")} */}
                    <input
                      hidden
                      accept="image/jpeg, image/png, image/webp"
                      type="file"
                      onChange={handleImage}
                    />
                  </Button>
                </Stack>
              </div>
            ) : (
              <div style={{ textAlign: "center", padding: "10px 0" }}>
                <Button
                  id="upload-button"
                  variant="contained"
                  component="label"
                  startIcon={<PhotoCamera />}
                >
                    Select
                  {/* {t("maps.select")} */}
                  <input
                    name="upload"
                    id="upload"
                    hidden
                    accept="image/jpeg, image/png, image/webp"
                    type="file"
                    onChange={handleImage}
                  />
                </Button>
              </div>
            )}
          </div>
          <Grid item xs={12} sm={12}>
            <Typography
              color="#D22108"
              fontWeight="500"
              component="h5"
              variant="subtitle1"
              align="left"
              gutterBottom
              marginTop={2}
              marginBottom={1}
            >
              Name
            </Typography>
            <TextField
              fullWidth
              size="small"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            </Grid>
          <Grid item xs={12} sm={12}>
            <Typography
              color="#D22108"
              fontWeight="500"
              component="h5"
              variant="subtitle1"
              align="left"
              gutterBottom
              marginTop={2}
              marginBottom={1}
            >
              Telegram
            </Typography>
            <TextField
              fullWidth
              size="small"
              label="Telegram"
              value={telegram}
              onChange={(e) => setTelegram(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography
              color="#D22108"
              fontWeight="500"
              component="h5"
              variant="subtitle1"
              align="left"
              gutterBottom
              marginTop={2}
              marginBottom={1}
            >
              WhatsApp
            </Typography>
            <MuiTelInput
              label="WhatsApp"
              fullWidth
              value={whatsApp}
              onChange={setWhatsApp}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography
              color="#D22108"
              fontWeight="500"
              component="h5"
              variant="subtitle1"
              align="left"
              gutterBottom
              marginTop={2}
              marginBottom={1}
            >
              Phone
            </Typography>
            <MuiTelInput
              label="Phone"
              fullWidth
              value={phone}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography
              color="#D22108"
              fontWeight="500"
              component="h5"
              variant="subtitle1"
              align="left"
              gutterBottom
              marginTop={2}
              marginBottom={1}
            >
              Facebook
            </Typography>
            <TextField
              value={fb}
              onChange={(e) => setFb(e.target.value)}
              fullWidth
              size="small"
              // name={t("maps.description")}
              label="Facebook Link"
              // id={t("maps.description")}
              // onChange={(data) => {
              //   setDescription(data.target.value);
              // }}
              // value={description}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography
              color="#D22108"
              fontWeight="500"
              component="h5"
              variant="subtitle1"
              align="left"
              gutterBottom
              marginTop={2}
              marginBottom={1}
            >
              LINE
            </Typography>
            <TextField
              fullWidth
              size="small"
              value={line}
              onChange={(e) => setLine(e.target.value)}
              // name={t("maps.description")}
              label="LINE"
              // id={t("maps.description")}
              // onChange={(data) => {
              //   setDescription(data.target.value);
              // }}
              // value={description}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography
              color="#D22108"
              fontWeight="500"
              component="h5"
              variant="subtitle1"
              align="left"
              gutterBottom
              marginTop={2}
              marginBottom={1}
            >
              Viber
            </Typography>
            <TextField
              fullWidth
              size="small"
              value={viber}
              onChange={(e) => setViber(e.target.value)}
              // name={t("maps.description")}
              label="Viber"
              // id={t("maps.description")}
              // onChange={(data) => {
              //   setDescription(data.target.value);
              // }}
              // value={description}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography
              color="#D22108"
              fontWeight="500"
              component="h5"
              variant="subtitle1"
              align="left"
              gutterBottom
              marginTop={2}
              marginBottom={1}
            >
              WeChat
            </Typography>
            <TextField
              fullWidth
              size="small"
              value={weChat}
              onChange={(e) => setWeChat(e.target.value)}
              // name={t("maps.description")}
              label="WeChat"
              // id={t("maps.description")}
              // onChange={(data) => {
              //   setDescription(data.target.value);
              // }}
              // value={description}
            />
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              backgroundColor: "#D22108",
              "&:hover": {
                backgroundColor: "#D22108",
                borderColor: "#D22108",
              },
            }}
            onClick={() => editprofile()}
          >
            Save
            {/* {t("maps.save")} */}
          </Button>
          {/* <Box display="flex" justifyContent="flex-end" p={1}>
          <Button variant="contained" color="primary" onClick={handleClickOpen}>
            You can Active or Deactive this user
          </Button>
        </Box> */}
        {/* <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Verify</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Are you sure you want to Active/Deactive?
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleReject} color="primary">
                Mark as Deactive
            </Button>
            <Button onClick={handleApprove} color="primary" autoFocus>
                Mark as Active
            </Button>
            </DialogActions>
        </Dialog> */}
        </Container>
        <div style={{ textAlign: "center", paddingTop: "40px" }}>
          {/* <GoogleLogout
              clientId={clientID}
              buttonText="Log out"
              onLogoutSuccess={logOut}
            /> */}
          <Button
            variant="contained"
            onClick={() => {
            //   googleLogout();
              localStorage.clear();
              navigate("/login", { replace: true });
            }}
          >
            logOut
            {/* {t("setting.logout")} */}
          </Button>
        </div>
      </Container>
    </div>
  );
}

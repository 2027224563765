import React, { useState, useEffect } from "react";
import {
  Typography,
  Card,
  CardMedia,
  CardContent,
  Button,
  Grid,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  AppBar,
  Toolbar,
  IconButton,
  TextField,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import GetApi from "../../utils/GETAPI";
import PostAPI from "../../utils/POSTAPI";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const DeceasedView = () => {
  const { state } = useLocation();
  const [data, setData] = useState([]);
  const [userStatusOpen, setUserStatusOpen] = useState(false);
  const [change, setChange] = useState("");
  const navigate = useNavigate();
  const [reason, setReason] = useState('');
  const handleClickStatusOpen = () => {
    setUserStatusOpen(true);
  }

  const handleUserStatusClose = () => {
    setUserStatusOpen(false);
  };

  useEffect(() => {
    const { id } = state;
    getReport(id);
  }, [change]);

  const getReport = (id) => {
    GetApi.GetDeceasedProfile(id,(response) => {
      if (!response.status) {
            setData([]);
          } else if (response.status) {
            setData(response.data)
          } else {
            setData([]);
          }
      });
  };

  const handleUserStatusApprove = () => {
    let formdata = {
      _id: data._id,
      status: true,
      reason: reason,
    };
    PostAPI.ChangeDeceasedStatus(formdata, (response) => {
      if (!response.status) {
        setData([]);
      } else if (response.status) {
        setChange("1");
      } else {
        setData([]);
      }
    });
    handleUserStatusClose();
  };

  const handleUserStatusReject = () => {
    let formdata = {
      _id: data._id,
      status: false,
      reason: reason,
    };
    PostAPI.ChangeDeceasedStatus(formdata, (response) => {
      if (!response.status) {
        setData([]);
      } else if (response.status) {
        setChange("2");
      } else {
        setData([]);
      }
    });
    handleUserStatusClose();
  };

  return (
    <>
      <Card
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <AppBar style={{ backgroundColor: "white" }} position="static">
          <Toolbar>
            <IconButton
              onClick={() => navigate(-1)}
              edge="start"
              color="inherit"
              aria-label="back"
            >
              <ArrowBackIcon style={{ color: "black" }} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <img
          style={{
            height: "400px",
            width: "60%",
            minWidth: "400px",
            maxWidth: "600px",
          }}
          src={data.photo}
        />
        <CardContent>
          <Typography
            gutterBottom
            margin={"10px 0"}
            variant="h5"
            component="h2"
          >
            {data.firstName}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>User ID</span>: {data._id}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>First Name</span>: {data.firstName}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>Created At</span>:{" "}
            {moment(data.createdAt).format("HH:MM A, DD MMMM YYYY")}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>Active Status</span>:{" "}
            {JSON.stringify(data.status)}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>Gender</span>:{" "}
            {data && data.gender ? Object.keys(data.gender).filter(key => data.gender[key] === true) : []}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>Age Range</span>:{" "}
            {data && data.ageRange ? Object.keys(data.ageRange).filter(key => data.ageRange[key] === true) : []} 
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>Date Found</span>:{" "}
            {JSON.stringify(data.dateWhenBodyIsFound)}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>Location</span>:{" "}
            {JSON.stringify(data.locationFound)}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>Ethnicity</span>:{" "}
            {JSON.stringify(data.ethnicity)}
          </Typography>
        </CardContent>
        <Box display="flex" justifyContent="flex-end" p={1}>
          <Button variant="contained" color="primary" style={{ flex: 1, margin: '0 4px' }} onClick={handleClickStatusOpen}>
          Verify as active user
          </Button>
        </Box>
      </Card>
      <Dialog 
        open={userStatusOpen}
        onClose={handleUserStatusClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Verify</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Are you sure you want to mark this user as Active?
          </DialogContentText>
          <TextField
          autoFocus
          margin="dense"
          id="reason"
          label="Reason"
          fullWidth
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          required
        />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUserStatusReject} color="primary">
          Mark as not active
          </Button>
          <Button onClick={handleUserStatusApprove}  color="primary" autoFocus>
          Mark as active
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeceasedView;

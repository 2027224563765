import React, { useState, useEffect } from "react";
import {
  Typography,
  Card,
  CardMedia,
  CardContent,
  Button,
  Grid,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import PostAPI from "../../../../utils/POSTAPI";
import GetApi from "../../../../utils/GETAPI";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const UserDetails = () => {
  const { state } = useLocation();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [change, setChange] = useState("");
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getReport();
  }, [change]);

  const getReport = () => {
    GetApi.GetUserList((response) => {
      if (!response.status) {
        setData([]);
      } else if (response.status) {
        let filterData = response.data.filter(
          (data) => data._id == state.obj._id
        );
        setData(filterData[0]);
      } else {
        setData([]);
      }
    });
  };

  const handleApprove = () => {
    let formdata = {
      _id: data._id,
      validatedBeneficiary: true,
    };
    PostAPI.EditUser(formdata, (response) => {
      if (!response.status) {
        alert("Something went wrong");
      } else if (response.status) {
        setChange("1");
      } else {
        alert("Something went wrong");
      }
    });
    handleClose();
  };

  const handleReject = () => {
    let formdata = {
      _id: data._id,
      validatedBeneficiary: false,
    };
    PostAPI.EditUser(formdata, (response) => {
      if (!response.status) {
        alert("Something went wrong");
      } else if (response.status) {
        setChange("2");
      } else {
        alert("Something went wrong");
      }
    });
    handleClose();
  };

  return (
    <>
      <Card
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <AppBar style={{ backgroundColor: "white" }} position="static">
          <Toolbar>
            <IconButton
              onClick={() => navigate(-1)}
              edge="start"
              color="inherit"
              aria-label="back"
            >
              <ArrowBackIcon style={{ color: "black" }} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <img
          style={{
            height: "400px",
            width: "60%",
            minWidth: "400px",
            maxWidth: "600px",
          }}
          src={data.picture}
        />
        <CardContent>
          <Typography
            gutterBottom
            margin={"10px 0"}
            variant="h5"
            component="h2"
          >
            {data.name}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>User ID</span>: {data._id}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>Email</span>: {data.email}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>Created At</span>:{" "}
            {moment(data.createdAt).format("HH:MM A, DD MMMM YYYY")}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>Last Updated At</span>:{" "}
            {moment(data.updatedAt).format("HH:MM A, DD MMMM YYYY")}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>UserName</span>:{" "}
            {data.userName}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>Validated Beneficiary</span>:{" "}
            {JSON.stringify(data.validatedBeneficiary)}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>Facebook</span>:{" "}
            {data.facebookInfo}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}> Line</span>: {data.lineInfo}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>Phone Number</span>:{" "}
            {data.phoneInfo}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>WhatsApp: </span>
            {data.whatsappInfo}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>WeChat: </span>
            {data.weChatInfo}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>Viber: </span>
            {data.viberInfo}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>Telegram: </span>
            {data.telegramInfo}
          </Typography>
        </CardContent>
        <Box display="flex" justifyContent="flex-end" p={1}>
          <Button variant="contained" color="primary" onClick={handleClickOpen}>
            Verify as validated beneficiary
          </Button>
        </Box>
      </Card>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Verify</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to mark this user as verified?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReject} color="primary">
            Mark as not verified
          </Button>
          <Button onClick={handleApprove} color="primary" autoFocus>
            Mark as Verified
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserDetails;

import React, { useState, useEffect } from "react";
import GetApi from "../../utils/GETAPI";
import { BottomNavigation, BottomNavigationAction , Collapse } from "@mui/material";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ReportIcon from "@mui/icons-material/Report";
import { useNavigate } from "react-router-dom";
import { ADMIN, DANGER_REPORTS, LOGIN, USERS, DASHBOARD, USERS_LIST , DECEASED , DANGER_LIST,ADD_CAT_VIEW,CATEGORY_LIST,REQUEST_LIST,ROLE,SERVICES_LIST} from "../../routes/routeConfig";
import MenuIcon from "@mui/icons-material/Menu";
import { PersonOutline as PersonOutlineIcon, Warning as WarningIcon, ExitToApp as ExitToAppIcon, Dashboard as DashboardIcon, People as PeopleIcon, ExpandLess, ExpandMore } from '@mui/icons-material';

const BottomTabs = () => {
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [data, setData] = useState([]);


  useEffect(() => {
    GetRoleReports();
  }, []);

  const GetRoleReports = () => {
    GetApi.GetRoleReports((response) => {
      if (!response.status) {
        setData([]);
      } else if (response.status) {
        const id = localStorage.getItem('_id');
        let filterData = response.data.filter(
          (data) => data._id == id
        );
        setData(filterData[0]);
      } else {
        setData([]);
      }
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      handleClick(DANGER_REPORTS);
    } else if (newValue === 1) {
      handleClick(USERS);
    }
  };

  const handleClick = (value) => {
    navigate(`/${ADMIN}/${value}`);
    setOpen(false);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(open);
  };

  const Logout = () => {
    localStorage.clear();
    navigate(`${LOGIN}`, { replace: true });
  };

  const handleSubMenuClick = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  return (
    <>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer(true)}
        style={{ alignSelf: "flex-start" }}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        open={open}
        onClose={toggleDrawer(false)}
        variant="temporary"
        anchor="left"
      >
      <List>
        <ListItem button onClick={() => handleClick(DASHBOARD)}>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        <ListItem button onClick={() => handleClick(USERS_LIST)}>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Users" />
        </ListItem>
        <ListItem button onClick={() => handleClick(REQUEST_LIST)}>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Request Report" />
        </ListItem>
        <ListItem button onClick={() => handleClick(DECEASED)}>
          <ListItemIcon>
            <PersonOutlineIcon />
          </ListItemIcon>
          <ListItemText primary="Deceased" />
        </ListItem>
        <ListItem button onClick={() => handleClick(SERVICES_LIST)}>
          <ListItemIcon>
            <PersonOutlineIcon />
          </ListItemIcon>
          <ListItemText primary="Other Services" />
        </ListItem>
        <ListItem button onClick={handleSubMenuClick}>
          <ListItemIcon>
            <WarningIcon />
          </ListItemIcon>
          <ListItemText primary="Danger"/>
          {isSubMenuOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={isSubMenuOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button>
              <ListItemText primary="Danger Locations" onClick={() => handleClick(DANGER_LIST)} />
            </ListItem>
            <ListItem button>
              <ListItemText primary="Categories" onClick={() => handleClick(CATEGORY_LIST)} />
            </ListItem>
          </List>
        </Collapse>
        {data && data.admintype && data.admintype == 'superadmin' && (
          <ListItem button onClick={() => handleClick(ROLE)}>
            <ListItemIcon>
              <PersonOutlineIcon />
            </ListItemIcon>
            <ListItemText primary="Role" />
          </ListItem>
        )}
        <ListItem button onClick={() => Logout()}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Log Out" />
        </ListItem>
      </List>
      </Drawer>
    </>
  );
};

export default BottomTabs;

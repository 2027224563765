import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Button,
} from "@mui/material";
import GetApi from "../../utils/GETAPI";
// import "./DangerReports.css";
import { truncateDescription } from "../../helpers/TruncateText";
import { useNavigate } from "react-router-dom";
import { ADMIN, REQUEST_VIEW, REQUEST_EDIT } from "../../routes/routeConfig";
import BottomTabs from "../../components/Field/Drawer";
import "../Users/UserList/Users.css";
import moment from "moment";
import DataTable from "react-data-table-component";
import { RequestColumn } from "./RequestColumn";
import { CSVLink, CSVDownload } from "react-csv";
import PostAPI from "../../utils/POSTAPI";
import { flatten } from 'lodash';

export const RequestList = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [filterUser, setFilterUser] = useState([]);
  const [change, setChange] = useState("");
  const fileName = "RequestData";

  useEffect(() => {
    GetAllRequestReports();
  }, [change]);

  const GetAllRequestReports = () => {
    GetApi.GetRequestData((response) => {
      if (!response.status) {
        setData([]);
      } else if (response.status) {
        setData(response.data);
        setFilterUser(response.data);
      } else {
        setData([]);
      }
    });
  };

  const handleRequestEdit = (id) => {
    navigate(`/${ADMIN}/${REQUEST_EDIT}`, { state: { id } });
  };

  const handleRequestView = (id) => {
    navigate(`/${ADMIN}/${REQUEST_VIEW}`, { state: { id } });
  };

  const handleRequestDelete = (id) => {
    GetApi.DeleteRequest(id, (response) => {
      if (!response.status) {
        setData([]);
      } else if (response.status) {
        setData(response.data);
        setChange("2");
      } else {
        setData([]);
      }
    });
  };

  useEffect(() => {
    const result = data.filter((requestData) => {
      return (
        (requestData.title && requestData.title.toLowerCase().match(search.toLowerCase())) ||
        (requestData.userName && requestData.userName.toLowerCase().match(search.toLocaleLowerCase())) ||
        (requestData.helpType && requestData.helpType.toLowerCase().match(search.toLocaleLowerCase())) ||
        (requestData.expiryDate && requestData.expiryDate.toLowerCase().match(search.toLocaleLowerCase()))
      );
    });
    setFilterUser(result);
  }, [search]);

  const columns = RequestColumn({
    handleRequestEdit,
    handleRequestDelete,
    handleRequestView,
  });

  const actions = [
    <div style={{ display: 'flex', gap: '10px' }}>
    <Button  sx={{ fontSize: "12px" ,bgcolor: '#d3200a' ,color: 'red' ,'&:hover': { bgcolor: 'green' }}}><CSVLink data={data} filename={`${fileName}.csv`} style={{color: 'white' ,textDecoration: 'unset' }}>Export</CSVLink></Button>
    </div>
  ];
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "30px",
        padding: "30px",
      }}
    >
      <BottomTabs />
      <ToastContainer />
      <DataTable
        title="Request List"
        columns={columns}
        data={filterUser}
        pagination
        highlightOnHover
        fixedHeader
        fixedHeaderScrollHeight="500px"
        selectableRows
        selectableRowsHighlight
        clearSelectedRows
        actions={actions}
        subHeader
        subHeaderComponent={
          <input
            type="text"
            placeholder="Search"
            className="w-25 form-control"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        }
        subHeaderAlign="right"
      />
    </Box>
  );
};
import React, { useState, useEffect } from "react";

import {
  Typography,
  Card,
  CardMedia,
  CardContent,
  Button,
  Grid,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  AppBar,
  Toolbar,
  IconButton,
  TextField
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import GetApi from "../../utils/GETAPI";
import PostAPI from "../../utils/POSTAPI";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const UserView = () => {
  const { state } = useLocation();
  const [open, setOpen] = useState(false);
  const [userStatusOpen, setUserStatusOpen] = useState(false);
  const [data, setData] = useState([]);
  const [change, setChange] = useState("");
  const navigate = useNavigate();
  const [reason, setReason] = useState('');
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickStatusOpen = () => {
    setUserStatusOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleUserStatusClose = () => {
    setUserStatusOpen(false);
  };

  useEffect(() => {
    getReport();
    GetCountRequestByUser();
  }, [change]);

  const getReport = () => {
    const { id } = state;
    GetApi.GetProfile(id,(response) => {
      if (!response.status) {
            setData([]);
          } else if (response.status) {
            setData(response.data)
          } else {
            setData([]);
          }
      });
  };

  const GetCountRequestByUser = () => {
    const { id } = state;
    GetApi.GetCountRequestByUser(id, (response) => {
      if (!response.status) {
        setData([]);
      } else if (response.status) {
        setData((prevData) => {
          const updatedData = { ...prevData, postCount: response.data };
          return updatedData;
        });
      } else {
        console.log("2");
        setData([]);
      }
    });
  };
  

  const handleApprove = () => {
    let formdata = {
      _id: data._id,
      status: true,
    };
    PostAPI.EditUser(formdata, (response) => {
      if (!response.status) {
        alert("Something went wrong");
      } else if (response.status) {
        setChange("1");
      } else {
        alert("Something went wrong");
      }
    });
    handleClose();
  };

  const handleReject = () => {
    let formdata = {
      _id: data._id,
      validatedBeneficiary: false,
    };
    PostAPI.EditUser(formdata, (response) => {
      if (!response.status) {
        alert("Something went wrong");
      } else if (response.status) {
        setChange("2");
      } else {
        alert("Something went wrong");
      }
    });
    handleClose();
  };

  const handleUserStatusApprove = () => {
    let formdata = {
      _id: data._id,
      status: true,
      reason: reason,
    };
    PostAPI.StatusUpdate(formdata, (response) => {
      if (!response.status) {
        alert("Something went wrong");
      } else if (response.status) {
        setChange("3");
      } else {
        alert("Something went wrong");
      }
    });
    handleUserStatusClose();
  };

  const handleUserStatusReject = () => {
    let formdata = {
      _id: data._id,
      status: false,
      reason: reason,
    };
    PostAPI.StatusUpdate(formdata, (response) => {
      if (!response.status) {
        alert("Something went wrong");
      } else if (response.status) {
        setChange("4");
      } else {
        alert("Something went wrong");
      }
    });
    handleUserStatusClose();
  };
  return (
    <>
      <Card
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <AppBar style={{ backgroundColor: "white" }} position="static">
          <Toolbar>
            <IconButton
              onClick={() => navigate(-1)}
              edge="start"
              color="inherit"
              aria-label="back"
            >
              <ArrowBackIcon style={{ color: "black" }} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <img
          style={{
            height: "400px",
            width: "60%",
            minWidth: "400px",
            maxWidth: "600px",
          }}
          src={data.picture}
        />
        <CardContent>
          <Typography
            gutterBottom
            margin={"10px 0"}
            variant="h5"
            component="h2"
          >
            {data.name}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>User ID</span>: {data._id}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>Email</span>: {data.email}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>Created At</span>:{" "}
            {moment(data.createdAt).format("HH:MM A, DD MMMM YYYY")}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>Last Updated At</span>:{" "}
            {moment(data.updatedAt).format("HH:MM A, DD MMMM YYYY")}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>UserName</span>:{" "}
            {data.userName}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>Validated Beneficiary</span>:{" "}
            {JSON.stringify(data.validatedBeneficiary)}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>Status</span>:{" "}
            {JSON.stringify(data.status)}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>Facebook</span>:{" "}
            {data.facebookInfo}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}> Line</span>: {data.lineInfo}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>Phone Number</span>:{" "}
            {data.phoneInfo}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>WhatsApp: </span>
            {data.whatsappInfo}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>WeChat: </span>
            {data.weChatInfo}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>Viber: </span>
            {data.viberInfo}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>Telegram: </span>
            {data.telegramInfo}
          </Typography>
        </CardContent>
        <Box display="flex" justifyContent="flex-end" p={1}>
          {/* <Button variant="contained" color="primary" style={{ flex: 1, margin: '0 4px' }} onClick={handleClickOpen}>
          Verify as validated beneficiary
          </Button> */}
          <Button variant="contained" color="primary" style={{ flex: 1, margin: '0 4px' }} onClick={handleClickStatusOpen}>
          Verify as active user
          </Button>
        </Box>
      </Card>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Verify</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Are you sure you want to mark this user as verified?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReject} color="primary">
          Mark as not verified
          </Button>
          <Button onClick={handleApprove} color="primary" autoFocus>
          Mark as Verified
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog 
        open={userStatusOpen}
        onClose={handleUserStatusClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Verify</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Are you sure you want to mark this user as Active?
          </DialogContentText>
          <TextField
          autoFocus
          margin="dense"
          id="reason"
          label="Reason"
          fullWidth
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          required
        />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUserStatusReject} color="primary">
          Mark as not active
          </Button>
          <Button onClick={handleUserStatusApprove}  color="primary" autoFocus>
          Mark as active
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserView;

import React, { useState, useEffect } from "react";
import {
  Routes,
  Route,
  Navigate,
  Link,
  useLocation,
  useRoutes,
} from "react-router-dom";  
import DangerReportDetails from "../pages/DangerReportDetails/DangerReportDetails";
import DangerReports from "../pages/DangerReports/DangerReports";
import Login from "../pages/Login/Login";
import UserDetails from "../pages/Users/UserList/DangerReportDetails/UserDetails";
import Users from "../pages/Users/UserList/UsersList";
import Register from "../pages/Register/Register";
import Dashboard from "../pages/Dashboard/Dashboard";
import UserData from "../pages/UserList/UserData";
import UserEdit from "../pages/UserList/UserEdit";
import UserView from "../pages/UserList/UserView";
import DeceasedList from "../pages/Deceased/DeceasedList";
import DeceasedView from "../pages/Deceased/DeceasedView";
import DangerList from "../pages/DangerReports/DangerList";
import DangerView from "../pages/DangerReports/DangerView";
import AddCategory from "../pages/UserList/Category/AddCategory";
import CategoryData from "../pages/UserList/Category/CategoryList";
import { CategoryView } from "../pages/UserList/Category/CategoryView";
import { EditCategory } from "../pages/UserList/Category/EditCategory";
import { RequestList } from "../pages/Request/RequestList";
import { RequestView } from "../pages/Request/RequestView";
import { RequestEdit } from "../pages/Request/RequestEdit";
import { RoleIndex } from "../pages/Role/RoleIndex";
import { AddRole } from "../pages/Role/AddRole";
import { EditRole } from "../pages/Role/EditRole";
import { ResetPassword } from "../pages/SuperAdmin/ResetPassword";
import PrivateRoutes from "./PrivateRoutes";
import ServiceData from "../pages/Others/ServicesList";
import AddServices from "../pages/Others/AddServices";
import { ServiceView } from "../pages/Others/ServicesView";
import { EditService } from "../pages/Others/EditServices";
import {
  DANGER_REPORTS,
  DANGER_REPORT_DETAILS,
  USERS,
  USERS_DETAILS,
  DASHBOARD,
  USERS_LIST,
  USERS_EDIT,
  USERS_VIEW,
  DECEASED,
  DECEASED_VIEW,
  DANGER_LIST,
  DANGER_VIEW,
  ADD_CAT_VIEW,
  CATEGORY_LIST,
  CATEGORY_VIEW,
  CATEGORY_EDIT,
  REQUEST_LIST,
  REQUEST_VIEW,
  REQUEST_EDIT,
  ROLE,
  ADD_ROLE_FORM,
  EDIT_ROLE_FORM,
  SUPERADMIN_RESET_PSS,
  SERVICES_LIST,
  ADD_SERVICES_VIEW,
  SERVICES_VIEW,
  SERVICES_EDIT,
} from "./routeConfig";

export const AppRoutes = () => {

  const [isLogin, setIsLogin] = useState(localStorage.getItem("_id"));

  useEffect(() => {
    setIsLogin(localStorage.getItem("_id"));
  }, []);

  const adminRoutes = [
    { path: DANGER_REPORTS, element: <DangerReports /> },
    { path: DANGER_REPORT_DETAILS, element: <DangerReportDetails /> },
    { path: USERS, element: <Users /> },
    { path: USERS_DETAILS, element: <UserDetails /> },
    { path: DASHBOARD, element: <Dashboard /> },
    { path: USERS_LIST, element: <UserData /> },
    { path: USERS_EDIT, element: <UserEdit /> },
    { path: USERS_VIEW, element: <UserView /> },
    { path: DECEASED, element: <DeceasedList /> },
    { path: DECEASED_VIEW, element: <DeceasedView /> },
    { path: DANGER_LIST, element: <DangerList /> },
    { path: DANGER_VIEW, element: <DangerView /> },
    { path: ADD_CAT_VIEW, element: <AddCategory /> },
    { path: CATEGORY_LIST, element: <CategoryData /> },
    { path: CATEGORY_VIEW, element: <CategoryView /> },
    { path: CATEGORY_EDIT, element: <EditCategory /> },
    { path: REQUEST_LIST, element: <RequestList /> },
    { path: REQUEST_VIEW, element: <RequestView /> },
    { path: REQUEST_EDIT, element: <RequestEdit /> },
    { path: ROLE, element: <RoleIndex /> },
    { path: ADD_ROLE_FORM, element: <AddRole /> },
    { path: EDIT_ROLE_FORM, element: <EditRole /> },
    { path: SERVICES_LIST, element: <ServiceData /> },
    { path: ADD_SERVICES_VIEW, element: <AddServices /> },
    { path: SERVICES_VIEW, element: <ServiceView /> },
    { path: SERVICES_EDIT, element: <EditService /> },
  ];
  const route = useRoutes([
    {
      path: "/",
      element: <PrivateRoutes />,
    },
    // {
    //   path: "/register",
    //   element: <Register />,
    // },
    {
      path: SUPERADMIN_RESET_PSS,
      element: <ResetPassword />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path:"admin",
      children: isLogin ? adminRoutes : <Navigate to="/login" replace />,
    },
  ]);
  return <div>{route}</div>;
};

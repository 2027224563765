import React, { useState , useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import Field from "../../components/Field/Field";
import { AppBar, Toolbar, IconButton, Typography, TextField, Grid, Button, Container } from '@mui/material';
import PostAPI from '../../utils/POSTAPI';
import GetApi from '../../utils/GETAPI';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const EditRole = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const { id } = location.state;
    GetRoleReports(id);
  }, []);

  const handleSubmit = () => {
    const { id } = location.state;
    let updatedData = {
        _id: id,
        username: username,
        email: email,
        password: password,
      };
    PostAPI.EditAdmin(updatedData, (response) => {
      if (!response.status) {
        alert("Something went wrong");
        window.location.reload();
      } else if (response.status) {
        alert("Admin updated succesfully");
        window.location.reload();
      } else {
        alert("Something went wrong");
        window.location.reload();
      }
    });
  };

  const GetRoleReports = (id) => {
    GetApi.GetRoleReports((response) => {
      if (!response.status) {
        setUsername('');
        setEmail('');
        setPassword('');
      } else if (response.status) {
        let filterData = response.data.filter(
            (data) => data._id == id
          );
        setUsername(filterData[0].username);
        setEmail(filterData[0].email);
        setPassword(filterData[0].password);
      } else {
        setUsername('');
        setEmail('');
        setPassword('');
      }
    });
  };

  return (
    <Grid className="container">
    <AppBar style={{ backgroundColor: "white" }} position="static">
        <Toolbar>
        <IconButton
            onClick={() => navigate(-1)}
            edge="start"
            color="inherit"
            aria-label="back"
        >
            <ArrowBackIcon style={{ color: "black" }} />
        </IconButton>
        </Toolbar>
    </AppBar>
    <div className="seperator" />
        Edit Admin
      <div className="seperator" />
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        noValidate
      >
        <Field
          onChange={(e) => setUsername(e.target.value)}
          value={username}
          label="Username"
        />
        <Field
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          label="Email"
        />
        <Field
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          label="Password"
        />
        <div className="seperator" />
        <Button
          // disabled={email !== "" && password !== "" ? false : true}
          onClick={handleSubmit}
          style={{ width: "330px",background: "#d3200a" }}
          variant="contained"
          color="primary"
        >
          Save
        </Button>
      </form>
    </Grid>
  );
};

import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Button,
} from "@mui/material";
import GetApi from "../../../utils/GETAPI";
// import "./DangerReports.css";
import { truncateDescription } from "../../../helpers/TruncateText";
import { useNavigate } from "react-router-dom";
import {
  ADMIN,
  DANGER_REPORT_DETAILS,
  LOGIN,
  USERS_DETAILS,
} from "../../../routes/routeConfig";
import BottomTabs from "../../../components/Field/Drawer";
import "./Users.css";
import moment from "moment";

function Users() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    GetUsers();
  }, []);

  const GetUsers = () => {
    GetApi.GetUserList((response) => {
      if (!response.status) {
        setData([]);
      } else if (response.status) {
        setData(response.data);
      } else {
        setData([]);
      }
    });
  };

  const Logout = () => {
    localStorage.clear();
    navigate(`${LOGIN}`, { replace: true });
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "30px",
        padding: "30px",
      }}
    >
      <BottomTabs />
      <Typography variant="h5">User List</Typography>
      <List style={{ width: "100%", maxWidth: 1000 }}>
        {data.length == 0 && (
          <Typography variant="subtitle1">
            <span style={{ fontWeight: "bold", fontSize: 30 }}>No Data</span>
          </Typography>
        )}
        {data.map((obj, index) => (
          <div
            onClick={() =>
              navigate(`/${ADMIN}/${USERS_DETAILS}`, { state: { obj } })
            }
            key={index}
            className="listContainer"
          >
            <ListItem>
              <ListItemAvatar>
                <img
                  referrerPolicy="no-referrer"
                  className="image"
                  src={obj.picture}
                />
              </ListItemAvatar>
              <Box
                marginLeft={"20px"}
                gap="5px"
                display="flex"
                flexDirection="column"
              >
                <Typography variant="subtitle1">
                  <span style={{ fontWeight: "bold" }}>Name:</span> {obj.name}
                </Typography>
                <Typography variant="body2">
                  <span style={{ fontWeight: "bold" }}>Email:</span> {obj.email}
                </Typography>
                <Typography variant="body2">
                  <span style={{ fontWeight: "bold" }}>User ID:</span> {obj._id}
                </Typography>
                <Typography variant="body2">
                  <span style={{ fontWeight: "bold" }}>UserName:</span>{" "}
                  {obj.userName}
                </Typography>
                <Typography variant="body2">
                  <span style={{ fontWeight: "bold" }}>Login Type:</span>{" "}
                  {obj.loginType}
                </Typography>
                <Typography variant="body2">
                  <span style={{ fontWeight: "bold" }}>
                    Validated Beneficiary:
                  </span>{" "}
                  {JSON.stringify(obj.validatedBeneficiary)}
                </Typography>
                <Typography variant="body2">
                  <span style={{ fontWeight: "bold" }}>Created At:</span>{" "}
                  {moment(obj.createdAt).format("HH:MM A, DD MMMM YYYY")}
                </Typography>
                <Typography variant="body2">
                  <span style={{ fontWeight: "bold" }}>Last Updated At:</span>{" "}
                  {moment(obj.updatedAt).format("HH:MM A, DD MMMM YYYY")}
                </Typography>
              </Box>
            </ListItem>
          </div>
        ))}
      </List>
    </Box>
  );
}

export default Users;

import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Button,
} from "@mui/material";
import GetApi from "../../../utils/GETAPI";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ADMIN,
  CATEGORY_VIEW,
  ADD_CAT_VIEW,
  CATEGORY_EDIT
} from "../../../routes/routeConfig";
import BottomTabs from "../../../components/Field/Drawer";
import "../../Users/UserList/Users.css";
import DataTable from "react-data-table-component";
import { CategoryColumn } from "./CategoryColumn";
import {CSVLink} from 'react-csv';
import { flatten } from 'lodash';
import Papa from 'papaparse';

const CategoryData = () => {

  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [filterCategory, setFilterCategory] = useState([]);
  const [change, setChange] = useState("");
  const fileName = "categoryData";

  const GetCategory = () => {
    GetApi.GetCategoryList((response) => {
      if (!response.status) {
        setData([]);
      } else if (response.status) {
        setData(response.data);
        setFilterCategory(response.data);
        setChange("1");
      } else {
        setData([]);
      }
    });
  };
  const handleAdd = () => {
    navigate(`/${ADMIN}/${ADD_CAT_VIEW}`)
  };
  const handleEdit = (id) => {
    navigate(`/${ADMIN}/${CATEGORY_EDIT}`, { state: { id } })
  };
  const handleView = (id) => {
    navigate(`/${ADMIN}/${CATEGORY_VIEW}`, { state: { id } })
  };
  const handleDelete = (id) => {
       GetApi.DeleteCategory(id, (response) => {
        if (!response.status) {
          setData([]);
        } else if (response.status) {
          setData(response.data);
          setChange("2");
        } else {
          setData([]);
        }
      });
  };

  const handleExportCsv = () => {
    const csvContent = Papa.unparse(data);
    const csvData = new Blob([csvContent], { type: 'text/csv' });
    const csvUrl = URL.createObjectURL(csvData);
    const link = document.createElement('a');
    link.href = csvUrl;
    link.setAttribute('download', 'categories.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    GetCategory();
  }, [change]);

  useEffect(() => {
    const result = data.filter((categories) => {
      const status = categories.status ? "Active" : "Deactivated";
      return (
        categories.name.toLowerCase().match(search.toLocaleLowerCase()) ||
        categories.createdAt.toLowerCase().match(search.toLocaleLowerCase()) ||
        status.toLowerCase().includes(search.toLowerCase())
      );
    });

    setFilterCategory(result);

  } , [search]);

  const columns = CategoryColumn({ handleView ,handleEdit , handleDelete });

  const actions = [
    <div style={{ display: 'flex', gap: '10px' }}>
    <button onClick={handleExportCsv}>
      Export CSV
    </button>
    <Button style={{ backgroundColor: '#3399cc', color: 'white' }} onClick={() => handleAdd()}> Add </Button>
    </div>
  ];

  return (
    <Box
    sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "30px",
        padding: "30px",
      }}
    >
      <BottomTabs/>
      <ToastContainer />
      <DataTable
        title="Categories"
        columns={columns}
        data={filterCategory}
        pagination
        highlightOnHover
        fixedHeader
        fixedHeaderScrollHeight="500px"
        selectableRows
        selectableRowsHighlight
        clearSelectedRows
        actions={actions}
        subHeader
        subHeaderComponent={
          <input 
            type="text" 
            placeholder="Search" 
            className="w-25 form-control" 
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        }
        subHeaderAlign="right"
      />
    </Box>
  );
}

export default CategoryData;

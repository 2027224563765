import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Button,
} from "@mui/material";
import BottomTabs from '../../components/Field/Drawer'
import { useNavigate } from "react-router-dom";
import GetApi from "../../utils/GETAPI";
// import mui
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import AccessibilityIcon from "@mui/icons-material/Accessibility";

function Dashboard() {
    const [data, setData] = useState({});
    const navigate = useNavigate();

    const GetUsersLength = () => {
      GetApi.GetUserList((response) => {
        setData((data) => ({ ...data, user: response.data.length }));
      });
     };

    const GetAllDangerReportsLength = () => {
        GetApi.GetAllDangerReports((response) => {
            setData((data) => ({ ...data, DangerReport: response.data.length }));
        });
    };

    const GetAllDeceasedListLength = () => {
        GetApi.GetDeceasedList((response) => {
            setData((data) => ({ ...data, DeceasedReport: response.data.length }));
        });
    }; 

    const GetAllRequestListLength = () => {
      GetApi.GetRequestData((response) => {
          setData((data) => ({ ...data, RequestData: response.data.length }));
      });
    }; 
      useEffect(() => {
        GetUsersLength();
        GetAllDangerReportsLength();
        GetAllDeceasedListLength();
        GetAllRequestListLength();
      }, []);

    const types = ['user', 'DangerReport', 'DeceasedReport', 'RequestData'];
    const reportName = ['Total Users', 'No. of danger locations', 'No. of deceased', 'No. of requests'];

    return (
      <Box
      sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "30px",
          padding: "30px",
        }}
      >
       <BottomTabs />
        <Typography variant="h2">Dashboard</Typography>
        <Divider />
        <Container maxWidth="lg" sx={{ mt: "7%" }}>
          <Grid container spacing={3}>
            {types.map((type, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Card className={`${type}-card card`} sx={{ p: 2, height: "100%" }}>
                  <CardMedia
                    className="services-icon"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: 80,
                      backgroundColor: "#f5f5f5",
                    }}
                    component={Typography}
                    variant="h5"
                  >
                    {reportName[index]}
                  </CardMedia>
                  <Typography
                    className="services-name"
                    align="center"
                    sx={{
                      mt: 1,
                      fontWeight: "bold",
                      color: "#D22108",
                      textTransform: "capitalize",
                    }}
                  >
                    {data[type]}
                  </Typography>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    );
}

export default Dashboard;

import React, { useState, useEffect } from "react";
import { Button, Typography, Grid, TextField } from "@mui/material";
import Field from "../../components/Field/Field";
import HELPLogo from "../../assets/logo.jpg";
import "./Login.css";
import PostAPI from "../../utils/POSTAPI";
import { emailValidation, passwordValidation } from "../../helpers/validators";
import { useNavigate } from "react-router";
import { ADMIN, SUPERADMIN_RESET_PSS, REGISTER , USERS_LIST} from "../../routes/routeConfig";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const navigate = useNavigate();

  const login = () => {
    if (!emailValidation(email)) {
      alert("Please Enter A Valid Email");
    } else if (password == "") {
      alert("Please Enter A Password");
    } else if (!passwordValidation(password)) {
      alert("Password should be of atleast 8 characters");
    } else {
      let formdata = {
        email,
        password,
      };
      PostAPI.Login(formdata, (response) => {
        if (!response.status) {
          alert(response.status);
          console.log(response.status);
          console.log(response);
          alert("Login failed");
        } else if (response.status) {
          localStorage.setItem("_id", response.data._id);
          alert("Login Successful");
          navigate(`/${ADMIN}/${USERS_LIST}`, { replace: true });
        } else {
          alert("Login Failed");
        }
      });
    }
  };
  return (
    <Grid className="container">
      <img style={{ height: "20%" }} src={HELPLogo} />
      <div className="seperator" />
      <Typography variant="h3" align="center">
        Gebirah Admin
      </Typography>
      <div className="seperator" />
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        noValidate
      >
        <Field
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          label="Email"
        />
        <TextField
          onChange={(e) => setPassword(e.target.value)}
          style={{ width: "330px" }}
          value={password}
          type="password"
          label="Password"
        />
        <div className="seperator" />
        <Button
          // disabled={email !== "" && password !== "" ? false : true}
          onClick={login}
          style={{ width: "330px" ,background: "#d3200a" }}
          variant="contained"
          color="primary"
        >
          Login
        </Button>
        <Button
          // disabled={email !== "" && password !== "" ? false : true}
          onClick={() =>
            navigate(`../${SUPERADMIN_RESET_PSS}`)
          }
          style={{ width: "330px",marginTop:"10px" ,background: "#d3200a" }}
          variant="contained"
          color="primary"
        >
          Super Admin can reset Password !
        </Button>
        {/* <Button
          // disabled={email !== "" && password !== "" ? false : true}
          onClick={() =>
            navigate(`../${REGISTER}`)
          }
          style={{ width: "330px" , marginTop:"10px" ,background: "black"}}
          variant="contained"
          color="primary"
        >
          Register
        </Button> */}
      </form>
    </Grid>
  );
}

import React from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Button,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit, faEye } from "@fortawesome/free-solid-svg-icons";

export const RequestColumn = ({ handleRequestEdit, handleRequestDelete , handleRequestView }) => [
{
    name: "No.",
    selector: (_, index) => index + 1,
    sortable: true,
    }, 
  {
    name: "Title",
    selector: row => row.title,
    sortable: true,
  },
  {
    name: "User Name",
    selector: row => row.userName,
    sortable: true,
  },
  {
    name: "Quantity",
    selector: row => row.quantity,
    sortable: true,
  },
//   {
//     name: "Category",
//     selector: row => Object.keys(row.category).filter(key => row.category[key] === true),
//     sortable: true,
//   },
//   {
//     name: "Photo",
//     cell: (row) => <img src={row.photo} alt="danger Image" style={{ width: '50px', height: '50px' }} />
//   },
  {
    name: "Latitude",
    selector: row => row.currentLocation.latitude,
    sortable: true,
  },
  {
    name: "Longitude",
    selector: row => row.currentLocation.longitude,
    sortable: true,
  },
  {
    name: "Help Type",
    selector: row => row.helpType,
    sortable: true,
  },
  {
    name: "Expiry Date",
    selector: row => row.expiryDate,
    sortable: true,
  },
  {
    name: "Created At",
    selector: row => row.createdAt,
    sortable: true,
  },
  {
    name: "Status",
    sortable: true,
    selector: (row) => (row.validatedBeneficiary ? "Validated" : "Not Validated"),
  },
  {
    name: "Action",
    sortable: false,
    cell: (row) => (
      <div style={{
        display: "flex",
      }}>
        <Button
          variant="danger"
          data-tag="allowRowEvents"
          data-action="delete"
          onClick={() => handleRequestDelete(row._id)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
        <Button variant="primary" onClick={() => handleRequestView(row._id)}>
          <FontAwesomeIcon icon={faEye} />
        </Button>
        <Button variant="primary" onClick={() => handleRequestEdit(row._id)}>
          <FontAwesomeIcon icon={faEdit} />
        </Button>
      </div>
    ),
  },
];

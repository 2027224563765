import React from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Button,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit, faEye } from "@fortawesome/free-solid-svg-icons";

export const RoleColumn = ({ handleChangeStatus ,handleDelete , handleEdit}) => [
{
    name: "No.",
    selector: (_, index) => index + 1,
    sortable: true,
    }, 
  {
    name: "Username",
    selector: row => row.username,
    sortable: true,
  },
  {
    name: "Email",
    selector: row => row.email,
    sortable: true,
  },
  {
    name: "Action",
    sortable: false,
    cell: (row) => (
    <div style={{ display: "flex" }}>
      {row.admintype !== 'superadmin' && (
        <>
          {row.status ? (
            <Button
              style={{ backgroundColor: "#b3e6cc", color: "#333333" }}
              onClick={() => handleChangeStatus(row._id, false)}
            >
              Disable
            </Button>
          ) : (
            <Button
              style={{ backgroundColor: "#cce0ff", color: "#333333" }}
              onClick={() => handleChangeStatus(row._id, true)}
            >
              Enable
            </Button>
          )}
          <Button variant="primary" onClick={() => handleEdit(row._id)}>
            <FontAwesomeIcon icon={faEdit} />
          </Button>
          <Button
            variant="danger"
            data-tag="allowRowEvents"
            data-action="delete"
            onClick={() => handleDelete(row._id)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </>
      )}
    </div>
    ),
  },
];
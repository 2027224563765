import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Field from "../../components/Field/Field";
import { AppBar, Toolbar, IconButton, Typography, TextField, Grid, Button, Container } from '@mui/material';
import PostAPI from '../../utils/POSTAPI';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const AddRole = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    let formData = {
      username: username,
      email: email,
      password: password,
    };
    PostAPI.Register(formData, (response) => {
    if (!response.status) {
        alert("failed");
    } else if (response.status) {
        alert("Admin Added Successfully");
        navigate(-1);
    } else {
        alert("something went wrong");
    }
    });
  };

  return (
    <Grid className="container">
    <AppBar style={{ backgroundColor: "white" }} position="static">
        <Toolbar>
        <IconButton
            onClick={() => navigate(-1)}
            edge="start"
            color="inherit"
            aria-label="back"
        >
            <ArrowBackIcon style={{ color: "black" }} />
        </IconButton>
        </Toolbar>
    </AppBar>
    <div className="seperator" />
        Add Admin
      <div className="seperator" />
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        noValidate
      >
        <Field
          onChange={(e) => setUsername(e.target.value)}
          value={username}
          label="Username"
        />
        <Field
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          label="Email"
        />
        <Field
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          label="Password"
        />
        <div className="seperator" />
        <Button
          // disabled={email !== "" && password !== "" ? false : true}
          onClick={handleSubmit}
          style={{ width: "330px",background: "#d3200a" }}
          variant="contained"
          color="primary"
        >
          Save
        </Button>
      </form>
    </Grid>
  );
};

import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Button,
} from "@mui/material";
import GetApi from "../../utils/GETAPI";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ADMIN,
  DANGER_REPORT_DETAILS,
  LOGIN,
  USERS_DETAILS,
  USERS_EDIT,
  USERS_VIEW,
} from "../../routes/routeConfig";
import BottomTabs from "../../components/Field/Drawer";
import "../Users/UserList/Users.css";
import moment from "moment";
import DataTable from "react-data-table-component";
import { UserColumn } from "./UserColumn";
import {CSVLink, CSVDownload} from 'react-csv';
import { flatten } from 'lodash';

const UserData = () => {

  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [filterUser, setFilterUser] = useState([]);
  const [change, setChange] = useState("");
  const fileName = "userData";

  const GetUsers = () => {
    GetApi.GetUserList((response) => {
      if (!response.status) {
        setData([]);
      } else if (response.status) {
        // Fetch request count for each user
        response.data.forEach((user) => {
          GetApi.GetCountRequestByUser(user._id, (countResponse) => {
            if (countResponse.status) {
              user.requestCount = countResponse.data;
            } else {
              user.requestCount = "-";
            }
          });
        });
        setData(response.data);
        setFilterUser(response.data);
        setChange("1");
      } else {
        setData([]);
      }
    });
  };

  const Logout = () => {
    localStorage.clear();
    navigate(`${LOGIN}`, { replace: true });
  };

  const handleEdit = (id) => {
    navigate(`/${ADMIN}/${USERS_EDIT}`, { state: { id } })  
  };
  const handleView = (id) => {
    navigate(`/${ADMIN}/${USERS_VIEW}`, { state: { id } })
  };
  const handleDelete = (id) => {
       GetApi.DeleteUser(id, (response) => {
        if (!response.status) {
          setData([]);
        } else if (response.status) {
          setData(response.data);
          setChange("2");
        } else {
          setData([]);
        }
      });
  };

  useEffect(() => {
    GetUsers();
  }, [change]);


  useEffect(() => {
    const result = data.filter((users) => {
      const status = users.status ? "Active" : "Deactivated";
      const validatedBeneficiary = users.validatedBeneficiary ? "Validated" : "Not Validated";
      return (
        users.name && users.name.toLowerCase().match(search.toLocaleLowerCase()) ||
        users.email && users.email.toLowerCase().match(search.toLocaleLowerCase()) ||
        users.updatedAt && users.updatedAt.toLowerCase().match(search.toLocaleLowerCase()) ||
        users.loginType && users.loginType.toLowerCase().match(search.toLocaleLowerCase()) ||
        status.toLowerCase().includes(search.toLowerCase()) ||
        validatedBeneficiary.toLowerCase().includes(search.toLowerCase())
      );
    });

    setFilterUser(result);

  } , [search]);

  // console.log("user data updated", data);
  const columns = UserColumn({ handleView ,handleEdit , handleDelete });

  return (
    <Box
    sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "30px",
        padding: "30px",
      }}
    >
      <BottomTabs/>
      <ToastContainer />
      <DataTable
        title="Users"
        columns={columns}
        data={filterUser}
        pagination
        highlightOnHover
        fixedHeader
        fixedHeaderScrollHeight="500px"
        selectableRows
        selectableRowsHighlight
        clearSelectedRows
        actions={<Button  sx={{ fontSize: "12px" ,bgcolor: '#d3200a' ,color: 'red' ,'&:hover': { bgcolor: 'green' }}}><CSVLink data={data} filename={`${fileName}.csv`} style={{color: 'white' ,textDecoration: 'unset' }}>Export</CSVLink></Button>}
        subHeader
        subHeaderComponent={
          <input 
            type="text" 
            placeholder="Search" 
            className="w-25 form-control" 
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        }
        subHeaderAlign="right"
      />
    </Box>
  );
}

export default UserData;

import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Button,
} from "@mui/material";
import GetApi from "../../utils/GETAPI";
// import "./DangerReports.css";
import { truncateDescription } from "../../helpers/TruncateText";
import { useNavigate } from "react-router-dom";
import { ADMIN, ADD_ROLE_FORM, EDIT_ROLE_FORM } from "../../routes/routeConfig";
import BottomTabs from "../../components/Field/Drawer";
import "../Users/UserList/Users.css";
import moment from "moment";
import DataTable from "react-data-table-component";
import { RoleColumn } from "./RoleColumn";
import { CSVLink, CSVDownload } from "react-csv";
import PostAPI from "../../utils/POSTAPI";
import { flatten } from 'lodash';

export const RoleIndex = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [filterUser, setFilterUser] = useState([]);
  const [change, setChange] = useState("");
  const fileName = "DangerData";

  useEffect(() => {
    GetRoleReports();
  }, [change]);

  const GetRoleReports = () => {
    GetApi.GetRoleReports((response) => {
      if (!response.status) {
        setData([]);
      } else if (response.status) {
        setData(response.data);
        setFilterUser(response.data);
      } else {
        setData([]);
      }
    });
  };

const handleChangeStatus = (id,status) => {
    const formdata = {
      _id: id,
      status: status,
    };
    PostAPI.AdminChangeStatus(formdata, (response) => {
        if (!response.status) {
        setData([]);
        } else if (response.status) {
          console.log("response",data);
        setData(response.data);
        setChange("1");
        } else {
        setData([]);
        }
    });
};

const handleAdd = () => {
  navigate(`/${ADMIN}/${ADD_ROLE_FORM}`);
};

const handleEdit = (id) => {
  navigate(`/${ADMIN}/${EDIT_ROLE_FORM}`, { state: { id } });
};

const handleDelete = (id) => {
  GetApi.DeleteAdmin(id, (response) => {
    if (!response.status) {
      setData([]);
    } else if (response.status) {
      setChange("2");
    } else {
      setData([]);
    }
  });
};
  
  useEffect(() => {
    const result = data.filter((admin) => {
      return (
        (admin.username &&
          admin.username.toLowerCase().match(search.toLowerCase())) ||
        (admin.email &&
          admin.email
            .toLowerCase()
            .match(search.toLocaleLowerCase()))
      );
    });
    setFilterUser(result);
  }, [search]);

  const columns = RoleColumn({
    handleChangeStatus,
    handleDelete , 
    handleEdit,
  });

  const actions = [
    <div style={{ display: 'flex', gap: '10px' }}>
    <Button style={{ backgroundColor: '#3399cc', color: 'white' }} onClick={() => handleAdd()}> Add </Button>
    </div>
  ];

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "30px",
        padding: "30px",
      }}
    >
      <BottomTabs />
      <ToastContainer />
      <DataTable
        title="Admin List"
        columns={columns}
        data={filterUser}
        pagination
        highlightOnHover
        fixedHeader
        fixedHeaderScrollHeight="500px"
        selectableRows
        selectableRowsHighlight
        clearSelectedRows
        actions={actions}
        subHeader
        subHeaderComponent={
          <input
            type="text"
            placeholder="Search"
            className="w-25 form-control"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        }
        subHeaderAlign="right"
      />
    </Box>
  );
};
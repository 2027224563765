import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import { googleLogout } from "@react-oauth/google";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
// import { PROFILE } from "navigation/routeConfig";
// import { clientID } from "utils/GoogleClientID";
// import { GoogleLogout } from "react-google-login";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { MuiTelInput } from "mui-tel-input";
import PostAPI from "../../utils/POSTAPI";
import GetApi from "../../utils/GETAPI";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Typography,
    Card,
    CardMedia,
    CardContent,
    Button,
    Grid,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    AppBar,
    Toolbar,
    IconButton,
    Stack,
    TextField
  } from "@mui/material";
  import ArrowBackIcon from "@mui/icons-material/ArrowBack";

  export const RequestEdit = ({}) => {

  let navigate = useNavigate();
  const { t } = useTranslation();
  const [title, setTitle] = useState();
  const [userName, setUserName] = useState();
  const [open, setOpen] = useState(false);
  const [change, setChange] = useState("");

  const location = useLocation();

  useEffect(() => {
    const { id } = location.state;
    Profile(id);
  }, []);

  const Profile = (id) => {
    GetApi.GetRequestData((response) => {
      if (!response.status) {
        setTitle("");
        setUserName("");
      } else if (response.status) {
        let filterData = response.data.filter(
          (data) => data._id == id
        );
        setTitle(filterData[0].title);
        setUserName(filterData[0].userName);
      } else {
        setTitle("");
        setUserName("");
      }
    });
  };

  const editprofile = () => {
    const { id } = location.state;
    const updatedData = {
        _id: id,
        title:title,
        userName:userName,
      };
    PostAPI.RequestProfileEdit(updatedData, (response) => {
      if (!response.status) {
        alert("Something went wrong");
        window.location.reload();
      } else if (response.status) {
        alert("Request updated succesfully");
        window.location.reload();
      } else {
        alert("Something went wrong");
        window.location.reload();
      }
    });
  };

  return (
    <div style={{ paddingBottom: "80px" }}>
        <AppBar style={{ backgroundColor: "white" }} position="static">
            <Toolbar>
              <IconButton
                onClick={() => navigate(-1)}
                edge="start"
                color="inherit"
                aria-label="back"
              >
                <ArrowBackIcon style={{ color: "black" }} />
              </IconButton>
            </Toolbar>
        </AppBar>
      <Container maxWidth="lg">
        <Container maxWidth="lg">
        <ToastContainer />
          <Grid item xs={12} sm={12}>
            <Typography
              color="#D22108"
              fontWeight="500"
              component="h5"
              variant="subtitle1"
              align="left"
              gutterBottom
              marginTop={2}
              marginBottom={1}
            >
              Title
            </Typography>
            <TextField
              fullWidth
              size="small"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            </Grid>
          <Grid item xs={12} sm={12}>
            <Typography
              color="#D22108"
              fontWeight="500"
              component="h5"
              variant="subtitle1"
              align="left"
              gutterBottom
              marginTop={2}
              marginBottom={1}
            >
              Username
            </Typography>
            <TextField
              fullWidth
              size="small"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              backgroundColor: "#D22108",
              "&:hover": {
                backgroundColor: "#D22108",
                borderColor: "#D22108",
              },
            }}
            onClick={() => editprofile()}
          >
            Save
            {/* {t("maps.save")} */}
          </Button>
        </Container>
      </Container>
    </div>
  );
}

export const ROOT = "/";
export const LOGIN = "/login";
// export const REGISTER = "/register";
export const DANGER_REPORTS = "dangerreports";
export const DANGER_REPORT_DETAILS = "dangerreportdetails";
export const USERS = "users";
export const USERS_DETAILS = "userdetails";
export const ADMIN = "admin";
export const DASHBOARD = "dashboard";
export const USERS_LIST = "userslist";
export const USERS_EDIT = "edituser";
export const USERS_VIEW = 'userview';
export const DECEASED = "deceased";
export const DECEASED_VIEW = "deceasedview";
export const DANGER_LIST = "dangerlist";
export const DANGER_VIEW = "dangerview";
export const ADD_CAT_VIEW = 'addcategory';
export const CATEGORY_LIST = 'categorylist';
export const CATEGORY_VIEW = 'categoryview';
export const CATEGORY_EDIT = "categoryedit";
export const REQUEST_LIST = "requestlist";
export const REQUEST_VIEW = "requestview";
export const REQUEST_EDIT = "requestedit";
export const ROLE = "role";
export const ADD_ROLE_FORM = 'addroleview';
export const EDIT_ROLE_FORM = 'editrole';
export const SUPERADMIN_RESET_PSS = 'admin/superadmin/changepassword';

export const ADD_SERVICES_VIEW = 'addservices';
export const SERVICES_LIST = 'serviceslist';
export const SERVICES_VIEW = 'servicesview';
export const SERVICES_EDIT = "servicesedit";
const emailValidation = (email) => {
  let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/;
  let isEmailValid = regexEmail.test(email);
  return isEmailValid;
};

const passwordValidation = (password) => {
  if (password.length < 8) {
    return false;
  } else {
    return true;
  }
};

export { emailValidation, passwordValidation };

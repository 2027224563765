import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import PostAPI from '../../../utils/POSTAPI';
import GetApi from '../../../utils/GETAPI';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { useTranslation } from "react-i18next";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {
  Typography,
  Card,
  CardMedia,
  CardContent,
  Button,
  Grid,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Toolbar,
  AppBar,
  IconButton,
  TextField,
  Stack
} from "@mui/material";
  import ArrowBackIcon from "@mui/icons-material/ArrowBack";

  export const EditCategory = ({}) => {

  const [imagePreview, setImagePreview] = useState(null);
  const [filename, setFilename] = useState("");
  const { t } = useTranslation();
  // To Preview Images
  const handleImage = async (e) => {
    e.preventDefault();
    let image = URL.createObjectURL(e.target.files[0]);

    setFilename(e.target.files[0].name);
    setImagePreview(image);
    setImgSrc(e.target.files[0]);
    };
    
  let navigate = useNavigate();
  const [data, setData] = useState();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState();
  const [displayType, setDisplayType] = React.useState(''); 
  const [change, setChange] = useState("");
  const location = useLocation();
  const [imgSrc, setImgSrc] = useState(null);
  const [icon, setIcon] = useState();
  useEffect(() => {
    const { id } = location.state;
    getCatData(id);
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSelectChange = (event) => {
    setDisplayType(event.target.value);
  };

  const getCatData = (id) => {
    GetApi.GetCategory(id,(response) => {
      if (!response.status) {
        setData([]);
      } else if (response.status) {
        setName(response.data.name);
        setDisplayType(response.data.displayType);
        setIcon(response.data.photo);
      } else {
        setData([]);
      }
    });
  };

  const { id } = location.state;

  const editprofile = () => {
   
    const updatedData = {
        _id: id,
        name:name,
        displayType: displayType,
        photo: imgSrc,
      };

    PostAPI.UpdateCategory(updatedData, (response) => {
      if (!response.status) {
        alert("Something went wrong");
        window.location.reload();
      } else if (response.status) {
        alert("Category updated succesfully");
        window.location.reload();
      } else {
        alert("Something went wrong");
        window.location.reload();
      }
    });
  };
  

  const handleApprove = () => {
    let formdata = {
      _id: id,
      status: true,
    };
    PostAPI.CategoryStatusChange(formdata, (response) => {
      if (!response.status) {
        alert("Something went wrong");
      } else if (response.status) {
        setChange("1");
        navigate(-1);
      } else {
        alert("Something went wrong");
      }
    });
    handleClose();
  };

  const handleReject = () => {
    let formdata = {
      _id: id,
      status: false,
    };
    PostAPI.CategoryStatusChange(formdata, (response) => {
      if (!response.status) {
        alert("Something went wrong");
      } else if (response.status) {
        setChange("2");
        navigate(-1);
      } else {
        alert("Something went wrong");
      }
    });
    handleClose();
  };

  return (
    <div style={{ paddingBottom: "80px" }}>
        <AppBar style={{ backgroundColor: "white" }} position="static">
            <Toolbar>
              <IconButton
                onClick={() => navigate(-1)}
                edge="start"
                color="inherit"
                aria-label="back"
              >
                <ArrowBackIcon style={{ color: "black" }} />
              </IconButton>
            </Toolbar>
        </AppBar>
      <Container maxWidth="lg">
        <Container maxWidth="lg">
        <ToastContainer />
          <Grid item xs={12} sm={12}>
            <Typography
              color="#D22108"
              fontWeight="500"
              component="h5"
              variant="subtitle1"
              align="left"
              gutterBottom
              marginTop={2}
              marginBottom={1}
            >
              Name
            </Typography>
            <TextField
              fullWidth
              size="small"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography
                color="#D22108"
                fontWeight="500"
                component="h5"
                variant="subtitle1"
                align="center"
                gutterBottom
                marginTop={2}
                marginBottom={1}
              >
                Display on screen:
              </Typography>
              <Select
                fullWidth
                value={displayType}
                onChange={handleSelectChange}
                variant="outlined"
              >
                <MenuItem value="Home">Home/Filter</MenuItem>
                <MenuItem value="Danger">Danger report screen</MenuItem>
              </Select>
            </Grid>
            <Grid>
            <img
                style={{
                  height: "400px",
                  width: "60%",
                  minWidth: "400px",
                  maxWidth: "600px",
                }}
                src={icon}
              />
             {imagePreview ? (
          <div>
            <div style={{ position: "relative", margin: "10px 0" }}>
              <img alt={filename} src={imagePreview} width={"100%"} />

              <div
                style={{
                  position: "absolute",
                  top: "0",
                  zIndex: "3",
                  width: "100%",
                  height: "100%",
                }}
              >
                {/* <canvas ref={canvas}></canvas> */}
              </div>
            </div>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
              <Button
                variant="contained"
                component="label"
                startIcon={<PhotoCamera />}
              >
                {t("Reupload")}
                <input
                  hidden
                  accept="image/jpeg, image/png, image/webp"
                  type="file"
                  onChange={handleImage}
                />
              </Button>
            </Stack>
          </div>
        ) : (
          <div style={{ textAlign: "center", padding: "10px 0" }}>
            <Button
              id="upload-button"
              variant="contained"
              component="label"
              startIcon={<PhotoCamera />}
            >
              {t("Select")}
              <input
                name="upload"
                id="upload"
                hidden
                accept="image/jpeg, image/png, image/webp"
                type="file"
                onChange={handleImage}
              />
            </Button>
          </div>
        )}
            </Grid>
            <Box display="flex" justifyContent="flex-end" p={1}>
            <Button variant="contained" color="primary" onClick={handleClickOpen}>
              Active/Deactive
            </Button>
          </Box>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              backgroundColor: "#D22108",
              "&:hover": {
                backgroundColor: "#D22108",
                borderColor: "#D22108",
              },
            }}
            onClick={() => editprofile()}
          >
            Save
            {/* {t("maps.save")} */}
          </Button>
          <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Approve/Reject Report</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to Active/Deactive this Category?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleReject} color="primary">
            Deactive
            </Button>
            <Button onClick={handleApprove} color="primary" autoFocus>
            Active
            </Button>
          </DialogActions>
        </Dialog>
        </Container>
      </Container>
    </div>
  );
}
import React from "react";
import { TextField } from "@mui/material";
import { styles } from "./styles.js";

export default function Field(props) {
  const { label, id, onChange, value } = props;
  return (
    <TextField
      sx={styles.container}
      id={id}
      label={label}
      onChange={onChange}
      value={value}
    />
  );
}

import React, { useState, useEffect } from "react";
import {
  Typography,
  Card,
  CardMedia,
  CardContent,
  Button,
  Grid,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Toolbar,
  AppBar,
  IconButton,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import PostAPI from "../../utils/POSTAPI";
import GetApi from "../../utils/GETAPI";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const RequestView = ({}) => {
  const { state } = useLocation();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [change, setChange] = useState("");
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getReport();
  }, [change]);

  const getReport = () => {
    const { id } = state;
    GetApi.GetRequestData((response) => {
      if (!response.status) {
        setData([]);
      } else if (response.status) {
        let filterData = response.data.filter(
          (data) => data._id == id
        );
        setData(filterData[0]);
      } else {
        setData([]);
      }
    });
  };

  const handleApprove = () => {
    let formdata = {
      _id: data._id
    };
    PostAPI.ApprovedReport(formdata, (response) => {
      if (!response.status) {
        alert("Something went wrong");
      } else if (response.status) {
        setChange("1");
      } else {
        alert("Something went wrong");
      }
    });
    handleClose();
  };

  const handleReject = () => {
    let formdata = {
      _id: data._id
    };
    PostAPI.ApprovedReport(formdata, (response) => {
      if (!response.status) {
        alert("Something went wrong");
      } else if (response.status) {
        setChange("2");
      } else {
        alert("Something went wrong");
      }
    });
    handleClose();
  };

  return (
    <>
      <Card
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <AppBar style={{ backgroundColor: "white" }} position="static">
          <Toolbar>
            <IconButton
              onClick={() => navigate(-1)}
              edge="start"
              color="inherit"
              aria-label="back"
            >
              <ArrowBackIcon style={{ color: "black" }} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <CardContent>
          <Typography
            gutterBottom
            margin={"10px 0"}
            variant="h5"
            component="h2"
          >
            Request Report by {data.userName}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>Title</span>: {data.title}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>Quantity</span>: {data.quantity}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>Reported At</span>:{" "}
            {moment(data.createdAt).format("HH:MM A, DD MMMM YYYY")}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>Latitude</span>:{" "}
            {data.currentLocation && data.currentLocation.latitude ? data.currentLocation.latitude : ''}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}> Longitude</span>:{" "}
            {data.currentLocation && data.currentLocation.longitude ? data.currentLocation.longitude : ''}
          </Typography>
            <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>Validated Beneficiary</span>:{" "}
            {data.validatedBeneficiary ? "Validate" : "Not Validate"}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>Help Type</span>:{" "}
            {data.helpType}
          </Typography>
          <Typography style={{ wordBreak: "break-word" }} variant="body2">
            <span style={{ fontWeight: "bold" }}>Expiry Date: </span>
            {data.expiryDate}
          </Typography>
          <Typography style={{ wordBreak: "break-word" }} variant="body2">
            <span style={{ fontWeight: "bold" }}>createdAt: </span>
            {data.createdAt}
          </Typography>
        </CardContent>
        {/* <Box display="flex" justifyContent="flex-end" p={1}>
          <Button variant="contained" color="primary" onClick={handleClickOpen}>
          Verify as validated
          </Button>
        </Box> */}
      </Card>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Approve/Reject Report</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to approve/reject this report?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReject} color="primary">
            Reject
          </Button>
          <Button onClick={handleApprove} color="primary" autoFocus>
            Approve
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
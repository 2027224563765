import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { LOGIN } from "./routeConfig";

const PrivateRoutes = () => {
  const [isLogin, setIsLogin] = useState(localStorage.getItem("_id"));

  useEffect(() => {
    setIsLogin(localStorage.getItem("_id"));
  }, []);

  return isLogin && isLogin.length > 0 ? (
    <Navigate
      replace
      to="/admin/userslist
  "
    />
  ) : (
    <Navigate replace to="/login" />
    // <Navigate replace to="/register" />
  );
};

export default PrivateRoutes;

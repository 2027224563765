import axios from "axios";
import { BaseURL } from "./BaseURL";
import { toast } from 'react-toastify';

const GetApi = {
  GetAllDangerReports: async (callback) => {
    axios({
      method: "GET",
      url: `${BaseURL}/helpoffered/getallmineimages`,
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        return callback(err);
      });
  },
  GetOneDangerReport: async (data, callback) => {
    axios({
      method: "GET",
      url: `${BaseURL}/helpoffered/oneDangerReport/${data}`,
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        return callback(err);
      });
  },
  GetUserList: async (callback) => {
    axios({
      method: "GET",
      url: `${BaseURL}/admin/getuserlist`,
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        return callback(err);
      });
  },
  GetOneUser: async (callback) => {
    axios({
      method: "GET",
      url: `${BaseURL}/admin/getoneuser`,
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        return callback(err);
      });
  },
  GetDeceasedList: async (callback) => {
    axios({
      method: "GET",
      url: `${BaseURL}/admin/getDeceasedList`,
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        return callback(err);
      });
  },

  GetRequestData: async (callback) => {
    axios({
      method: "GET",
      url: `${BaseURL}/admin/getRequestList`,
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        return callback(err);
      });
  },
  DeleteUser: async (userId, callback) => {
    axios({
      method: "delete",
      url: `${BaseURL}/admin/deleteUser/${userId}`,
    })
      .then((response) => {
        toast.success("User deleted successfully");
        // return callback(response.data);
      })
      .catch((err) => {
        return callback(err);
      });
  },
  DeleteDeceased: async (userId, callback) => {
    axios({
      method: "delete",
      url: `${BaseURL}/admin/deleteDeceased/${userId}`,
    })
      .then((response) => {
        toast.success("User deleted successfully");
        return callback(response.data);
      })
      .catch((err) => {
        return callback(err);
      });
  },
  GetProfile: async (userId , callback) => {
    axios({
      method: "GET",
      url: `${BaseURL}/admin/getUserProfile/${userId}`,
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        callback(err);
      });
  },
  GetDeceasedProfile: async (userId , callback) => {
    axios({
      method: "GET",
      url: `${BaseURL}/admin/getDeceasedProfile/${userId}`,
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        callback(err);
      });
  },
  DeleteDangerLocation: async (userId, callback) => {
    axios({
      method: "delete",
      url: `${BaseURL}/admin/deleteDangerLocation/${userId}`,
    })
      .then((response) => {
        toast.success("User deleted successfully");
        return callback(response.data);
      })
      .catch((err) => {
        return callback(err);
      });
  },
  GetCountRequestByUser: async (id, callback) => {
    axios({
      method: "GET",
      url: `${BaseURL}/admin/getCountRequestByUser/${id}`,
    })
      .then((response) => {
        return callback(response.data);
        toast.success("data get successfully");
      })
      .catch((err) => {
        callback(err);
      });
  },
  GetCategoryList: async (callback) => {
    axios({
      method: "GET",
      url: `${BaseURL}/admin/getcategorylist`,
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        return callback(err);
      });
  },
  GetCategory: async (userId , callback) => {
    axios({
      method: "GET",
      url: `${BaseURL}/admin/getcategory/${userId}`,
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        callback(err);
      });
  },
  DeleteCategory: async (catId, callback) => {
    axios({
      method: "delete",
      url: `${BaseURL}/admin/deletecategory/${catId}`,
    })
      .then((response) => {
        toast.success("Category deleted successfully");
        return callback(response.data);
      })
      .catch((err) => {
        return callback(err);
      });
  },
  DeleteRequest: async (requestId, callback) => {
    axios({
      method: "delete",
      url: `${BaseURL}/admin/deleterequest/${requestId}`,
    })
      .then((response) => {
        toast.success("Request deleted successfully");
        return callback(response.data);
      })
      .catch((err) => {
        return callback(err);
      });
  },
  GetRoleReports: async (callback) => {
    axios({
      method: "GET",
      url: `${BaseURL}/admin/getrolelist`,
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        return callback(err);
      });
  },
  DeleteAdmin: async (id, callback) => {
    axios({
      method: "delete",
      url: `${BaseURL}/admin/deleteadmin/${id}`,
    })
      .then((response) => {
        toast.success("Admin deleted successfully");
        return callback(response.data);
      })
      .catch((err) => {
        return callback(err);
      });
  },
  /**
   * Other service api start
   */
  GetServicesList: async (callback) => {
    axios({
      method: "GET",
      url: `${BaseURL}/admin/getserviceslist`,
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        return callback(err);
      });
  },
  GetServices: async (servicesId , callback) => {
    axios({
      method: "GET",
      url: `${BaseURL}/admin/getservices/${servicesId}`,
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        callback(err);
      });
  },
  DeleteServices: async (servicesId, callback) => {
    axios({
      method: "delete",
      url: `${BaseURL}/admin/deleteservices/${servicesId}`,
    })
      .then((response) => {
        toast.success("Services deleted successfully");
        return callback(response.data);
      })
      .catch((err) => {
        return callback(err);
      });
  },
  /**
   * Other service api end
   */
};
export default GetApi;
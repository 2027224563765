import axios from "axios";
import { BaseURL } from "./BaseURL";
import { toast } from 'react-toastify';

const PostAPI = {
  Register: async (data, callback) => {
    axios({
      method: "POST",
      url: `${BaseURL}/admin/adminRegisterr`,
      data: data,
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        callback(err);
      });
  },
  Login: async (data, callback) => {
    axios({
      method: "POST",
      url: `${BaseURL}/admin/adminlogin`,
      data: data,
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        callback(err);
      });
  },
  ApprovedReport: async (data, callback) => {
    axios({
      method: "POST",
      url: `${BaseURL}/admin/manageDangerPost`,
      data: data,
    })
      .then((response) => {
        alert(response.data.msg);
        return callback(response.data);
      })
      .catch((err) => {
        callback(err);
      });
  },
  EditUser: async (data, callback) => {
    axios({
      method: "POST",
      url: `${BaseURL}/admin/edituser`,
      data: data,
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        callback(err);
      });
  },
  EditProfile: async (data, callback) => {
    axios({
      method: "POST",
      url: `${BaseURL}/admin/editprofile`,
      data: data,
    })
      .then((response) => {
        return callback(response.data);
        toast.success("Profile Updated successfully");
      })
      .catch((err) => {
        callback(err);
      });
  },
  StatusUpdate:async (data, callback) => {
    axios({
      method: "POST",
      url: `${BaseURL}/admin/statusUpdate`,
      data: data,
    })
      .then((response) => {
        return callback(response.data);
        toast.success("Profile Updated successfully");
      })
      .catch((err) => {
        callback(err);
      });
  },
  ChangeDeceasedStatus:async (data, callback) => {
    axios({
      method: "POST",
      url: `${BaseURL}/admin/changeDeceasedStatus`,
      data: data,
    })
      .then((response) => {
        return callback(response.data);
        toast.success("Profile Updated successfully");
      })
      .catch((err) => {
        callback(err);
      });
  },
  ValidateDangerStatus:async (data, callback) => {
    axios({
      method: "POST",
      url: `${BaseURL}/admin/validateDangerStatus`,
      data: data,
    })
      .then((response) => {
        return callback(response.data);
        toast.success("Profile Updated successfully");
      })
      .catch((err) => {
        callback(err);
      });
  },
  AddCategory:async (data, callback) => {
    axios({
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      url: `${BaseURL}/admin/addCategory`,
      data: data,
    })
      .then((response) => {
        return callback(response.data);
        toast.success("Profile Updated successfully");
      })
      .catch((err) => {
        callback(err);
      });
  },
  UpdateCategory:async (data, callback) => {
    axios({
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      url: `${BaseURL}/admin/updateCategory`,
      data: data,
    })
      .then((response) => {
        return callback(response.data);
        toast.success("Category Updated successfully");
      })
      .catch((err) => {
        callback(err);
      });
  },
  CategoryStatusChange: async (data, callback) => {
    axios({
      method: "POST",
      url: `${BaseURL}/admin/categoryStatus`,
      data: data,
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        callback(err);
      });
  },
  RequestProfileEdit: async (data, callback) => {
    axios({
      method: "POST",
      url: `${BaseURL}/admin/requestedit`,
      data: data,
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        callback(err);
      });
  },
  AdminChangeStatus: async (data, callback) => {
    axios({
      method: "POST",
      url: `${BaseURL}/admin/adminchangestatus`,
      data: data,
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        callback(err);
      });
  },
  EditAdmin: async (data, callback) => {
    axios({
      method: "POST",
      url: `${BaseURL}/admin/editadmin`,
      data: data,
    })
      .then((response) => {
        return callback(response.data);
        toast.success("Profile Updated successfully");
      })
      .catch((err) => {
        callback(err);
      });
  },
  SuperAdminResetPassword: async (data, callback) => {
    axios({
      method: "POST",
      url: `${BaseURL}/admin/superadminresetpassword`,
      data: data,
    })
      .then((response) => {
        return callback(response.data);
        toast.success("Reset Password successfully");
      })
      .catch((err) => {
        callback(err);
      });
  },
  /**
   * Other service api start
   */
  AddServices:async (data, callback) => {
    axios({
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      url: `${BaseURL}/admin/addServices`,
      data: data,
    })
      .then((response) => {
        return callback(response.data);
        toast.success("Profile Updated successfully");
      })
      .catch((err) => {
        callback(err);
      });
  },
  UpdateServices:async (data, callback) => {
    axios({
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      url: `${BaseURL}/admin/updateservices`,
      data: data,
    })
      .then((response) => {
        return callback(response.data);
        toast.success("Category Updated successfully");
      })
      .catch((err) => {
        callback(err);
      });
  },
  ServicesStatusChange: async (data, callback) => {
    axios({
      method: "POST",
      url: `${BaseURL}/admin/servicesStatus`,
      data: data,
    })
      .then((response) => {
        return callback(response.data);
      })
      .catch((err) => {
        callback(err);
      });
  },
  /**
   * Other service api end
   */
};

export default PostAPI;

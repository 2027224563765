import React from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Button,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit, faEye } from "@fortawesome/free-solid-svg-icons";

export const DeceasedColumn = ({ handleDeceasedDelete , handleDeceasedView }) => [
{
    name: "No.",
    selector: (_, index) => index + 1,
    sortable: true,
    }, 
  {
    name: "ID",
    selector: row => row._id,
    sortable: true,
  },
  {
    name: "First Name",
    selector: row => row.firstName,
    sortable: true,
  },
  // {
  //   name: "Last Name",
  //   selector: row => row.lastName,
  //   sortable: true,
  // },
  // {
  //   name: "Poster's Name",
  //   selector: row => row.nickName,
  //   sortable: true,
  // },
  {
    name: "Age Range",
    selector: row => Object.keys(row.ageRange).filter(key => row.ageRange[key] === true),
    sortable: true,
  },
  {
    name: "Hair Color",
    selector: row => Object.keys(row.hairColor).filter(key => row.hairColor[key] === true),
    sortable: true,
  },
  {
    name: "Hair Length",
    selector: row => Object.keys(row.hairLength).filter(key => row.hairLength[key] === true),
    sortable: true,
  },
  {
    name: "Skin Color",
    selector: row => Object.keys(row.skinColor).filter(key => row.skinColor[key] === true),
    sortable: true,
  },
  {
    name: "Gender",
    selector: row => Object.keys(row.gender).filter(key => row.gender[key] === true),
    sortable: true,
  },
  {
    name: "Location",
    selector: row => row.locationFound,
    sortable: true,
  },
  {
    name: "Photo",
    cell: (row) => <img src={row.photo} alt="User Image" style={{ width: '50px', height: '50px' }} />
  },
  {
    name: "Date When Body Is Found",
    selector: row => row.dateWhenBodyIsFound,
  },
  {
    name: "Ethnicity",
    selector: row => row.ethnicity,
  },
  {
    name: "Created At",
    selector: row => row.createdAt,
    sortable: true,
  },
  {
    name: "Status",
    sortable: false,
    cell: (row) => row.status ? <Typography variant="primary" >Approved </Typography>: <Typography variant="primary" > Disapproved </Typography>
    // cell: (row) => (
    //     <div>
    //     {row.status === true ? (
    //       <Button onClick={() => handleChangeStatus(row._id , false)} variant="success">
    //         Approve
    //       </Button>
    //     ) : (
    //       <Button onClick={() => handleChangeStatus(row._id , true)} variant="danger">
    //         Not Approve
    //       </Button>
    //     )}
    //   </div>
    // ),
  },
  {
    name: "Action",
    sortable: false,
    cell: (row) => (
      <div style={{
        display: "flex",
      }}>
        <Button
          variant="danger"
          data-tag="allowRowEvents"
          data-action="delete"
          onClick={() => handleDeceasedDelete(row._id)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
        <Button variant="primary" onClick={() => handleDeceasedView(row._id)}>
          <FontAwesomeIcon icon={faEye} />
        </Button>
      </div>
    ),
  },
];

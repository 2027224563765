import React from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Button,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit, faEye } from "@fortawesome/free-solid-svg-icons";

export const DangerColumn = ({ handleChangeStatus, handleDangerDelete , handleDangerView }) => [
{
    name: "No.",
    selector: (_, index) => index + 1,
    sortable: true,
    }, 
  {
    name: "Name",
    selector: row => row.name,
    sortable: true,
  },
  {
    name: "ID",
    selector: row => row._id,
    sortable: true,
  },
  {
    name: "Category",
    selector: row => row.category,
    sortable: true,
  },
  {
    name: "Photo",
    cell: (row) => <img src={row.photo} alt="danger Image" style={{ width: '50px', height: '50px' }} />
  },
  {
    name: "Location",
    selector: row => row.mineLocationName,
    sortable: true,
  },
  {
    name: "Created At",
    selector: row => row.createdAt,
    sortable: true,
  },
  { 
    name: "Status",
    sortable: false,
    cell: (row) => row.adminApproved ? <Typography variant="primary" >Validated </Typography>: <Typography variant="primary" > Not Validated </Typography>
  },
  {
    name: "Action",
    sortable: false,
    cell: (row) => (
      <div style={{
        display: "flex",
      }}>
        <Button
          variant="danger"
          data-tag="allowRowEvents"
          data-action="delete"
          onClick={() => handleDangerDelete(row._id)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
        <Button variant="primary" onClick={() => handleDangerView(row._id)}>
          <FontAwesomeIcon icon={faEye} />
        </Button>
      </div>
    ),
  },
];

import React from "react";
import { Outlet } from "react-router-dom";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./App.css";
import { AppRoutes } from "./routes/AppRoutes";

function App() {
  return (
    <div className="App">
      {/* <ThemeProvider theme={theme}> */}
      {/* overwrite css order */}
      {/* <StyledEngineProvider injectFirst> */}
      {/* all routes found in AppRoutes */}
      <AppRoutes />
      <Outlet />
      {/* </StyledEngineProvider> */}
      {/* </ThemeProvider> */}
    </div>
  );
}

export default App;

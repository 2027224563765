import React, { useState, useEffect } from "react";
import {
  Typography,
  Card,
  CardMedia,
  CardContent,
  Button,
  Grid,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Toolbar,
  AppBar,
  IconButton,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import PostAPI from "../../utils/POSTAPI";
import GetApi from "../../utils/GETAPI";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const ServiceView = ({}) => {
  
  const { state } = useLocation();
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getReport();
  }, []);

  const getReport = () => {
    const { id } = state;
    GetApi.GetServicesList((response) => {
      if (!response.status) {
        setData([]);
      } else if (response.status) {
        let filterData = response.data.filter(
          (data) => data._id == id
        );
        setData(filterData[0]);
      } else {
        setData([]);
      }
    });
  };

  return (
    <>
      <Card
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <AppBar style={{ backgroundColor: "white" }} position="static">
          <Toolbar>
            <IconButton
              onClick={() => navigate(-1)}
              edge="start"
              color="inherit"
              aria-label="back"
            >
              <ArrowBackIcon style={{ color: "black" }} />
            </IconButton>
          </Toolbar>
        </AppBar>
        {data.photo ? (
          <img
          style={{
            height: "400px",
            width: "60%",
            minWidth: "400px",
            maxWidth: "600px",
          }}
          src={data.photo}
        />
        ):(
          <span> No icon found</span>
        )}
        
        <CardContent>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>Service ID</span>: {data._id}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>Title</span>: {data.name}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>Url</span>: {data.url}
          </Typography>
          <Typography style={{ wordBreak: "break-word" }} variant="body2">
            <span style={{ fontWeight: "bold" }}>Status: </span>
            {data.status ? "Active" : "Deactivated"}
          </Typography>
        </CardContent>
      </Card>
    </>
  );
};

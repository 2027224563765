import React, { useState, useEffect } from "react";
import {
  Typography,
  Card,
  CardMedia,
  CardContent,
  Button,
  Grid,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Toolbar,
  AppBar,
  IconButton,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import PostAPI from "../../utils/POSTAPI";
import GetApi from "../../utils/GETAPI";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const DangerReportDetails = ({}) => {
  const { state } = useLocation();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [change, setChange] = useState("");
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getReport();
    // getOneReport();
  }, [change]);

  // const getOneReport = () => {
  //   // let formdata = { _id: state.obj._id };
  //   GetApi.GetOneDangerReport(state.obj._id, (response) => {
  //     console.log(response);
  //   });
  // };

  const getReport = () => {
    GetApi.GetAllDangerReports((response) => {
      if (!response.status) {
        setData([]);
      } else if (response.status) {
        let filterData = response.data.filter(
          (data) => data._id == state.obj._id
        );
        setData(filterData[0]);
      } else {
        setData([]);
      }
    });
  };

  const handleApprove = () => {
    let formdata = {
      _id: data._id,
      adminApproved: true,
    };
    PostAPI.ApprovedReport(formdata, (response) => {
      if (!response.status) {
        alert("Something went wrong");
      } else if (response.status) {
        setChange("1");
      } else {
        alert("Something went wrong");
      }
    });
    handleClose();
  };

  const handleReject = () => {
    let formdata = {
      _id: data._id,
      adminApproved: false,
    };
    PostAPI.ApprovedReport(formdata, (response) => {
      if (!response.status) {
        alert("Something went wrong");
      } else if (response.status) {
        setChange("2");
      } else {
        alert("Something went wrong");
      }
    });
    handleClose();
  };

  return (
    <>
      <Card
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <AppBar style={{ backgroundColor: "white" }} position="static">
          <Toolbar>
            <IconButton
              onClick={() => navigate(-1)}
              edge="start"
              color="inherit"
              aria-label="back"
            >
              <ArrowBackIcon style={{ color: "black" }} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <img
          style={{
            height: "400px",
            width: "60%",
            minWidth: "400px",
            maxWidth: "600px",
          }}
          src={data.photo}
        />
        <CardContent>
          <Typography
            gutterBottom
            margin={"10px 0"}
            variant="h5"
            component="h2"
          >
            Danger Report by {data.name}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>User ID</span>: {data.userId}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>Email</span>: {data.email}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>Reported At</span>:{" "}
            {moment(data.createdAt).format("HH:MM A, DD MMMM YYYY")}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>Hidden</span>:{" "}
            {JSON.stringify(data.hidden)}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>Approved</span>:{" "}
            {JSON.stringify(data.adminApproved)}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>Latitude</span>:{" "}
            {data.mineLatitude}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}> Longitude</span>:{" "}
            {data.mineLongitude}
          </Typography>
          <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>Location Name</span>:{" "}
            {data.mineLocationName}
          </Typography>
          {/* <Typography gutterBottom margin={"10px 0"} variant="body2">
            <span style={{ fontWeight: "bold" }}>Category</span>:{" "}
            {data.category.UnexplodedMinesandBombs &&
              "Unexploded Mines & Bombs"}
            {data.category.UnstableStructuresandBuildings &&
              "Unstable Structures & Buildings"}
            {data.category.RoadBlocks && "Road Blocks"}
            {data.category.AttackersSpotted && "Attacker(s) Spotted"}
            {data.category.Floods && "Floods"}
            {data.category.Fire && "Fire"}
            {data.category.Earthquake && "Earthquake"}
            {data.category.Others && "Others"}
          </Typography> */}
          <Typography style={{ wordBreak: "break-word" }} variant="body2">
            <span style={{ fontWeight: "bold" }}>Description: </span>
            {data.description}
          </Typography>
        </CardContent>
        <Box display="flex" justifyContent="flex-end" p={1}>
          <Button variant="contained" color="primary" onClick={handleClickOpen}>
            Approve/Reject
          </Button>
        </Box>
      </Card>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Approve/Reject Report</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to approve/reject this report?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReject} color="primary">
            Reject
          </Button>
          <Button onClick={handleApprove} color="primary" autoFocus>
            Approve
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DangerReportDetails;

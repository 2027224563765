import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { flatten } from 'lodash';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Button,
} from "@mui/material";
import GetApi from "../../utils/GETAPI";
// import "./DangerReports.css";
import { truncateDescription } from "../../helpers/TruncateText";
import { useNavigate } from "react-router-dom";
import { ADMIN, DECEASED_VIEW } from "../../routes/routeConfig";
import BottomTabs from "../../components/Field/Drawer";
import "../Users/UserList/Users.css";
import moment from "moment";
import DataTable from "react-data-table-component";
import { DeceasedColumn } from "./DeceasedColumn";
import { CSVLink, CSVDownload } from "react-csv";
import PostAPI from "../../utils/POSTAPI";

const DeceasedList = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [filterUser, setFilterUser] = useState([]);
  const [change, setChange] = useState("");
  const fileName = "DeceasedData";

  useEffect(() => {
    GetDeceased();
  }, [change]);

  const GetDeceased = () => {
    GetApi.GetDeceasedList((response) => {
      if (!response.status) {
        setData([]);
      } else if (response.status) {
        setData(response.data);
        setFilterUser(response.data);
      } else {
        setData([]);
      }
    });
  };

  // const handleChangeStatus = (id, status) => {
  //   let formdata = {
  //     _id: id,
  //     status: status,
  //   };
  //   PostAPI.ChangeDeceasedStatus(formdata, (response) => {
  //     if (!response.status) {
  //       setData([]);
  //     } else if (response.status) {
  //       setData(response.data);
  //       console.log("deceased data 2",response);
  //       setChange("1");
  //       // window.location.reload();
  //     } else {
  //       setData([]);
  //     }
  //   });
  // };

  const handleDeceasedView = (id) => {
    console.log("handleView", id);
    navigate(`/${ADMIN}/${DECEASED_VIEW}`, { state: { id } });
  };

  const handleDeceasedDelete = (id) => {
    GetApi.DeleteDeceased(id, (response) => {
      if (!response.status) {
        setData([]);
      } else if (response.status) {
        setData(response.data);
        setChange("2");
        // window.location.reload();
      } else {
        setData([]);
      }
    });
  };

  // Flattening the nested object
  const flattenedData = filterUser.map(item => ({
    ...item,
    gender: flatten(Object.keys(item.gender).filter(key => item.gender[key] === true)),
    ageRange: flatten(Object.keys(item.ageRange).filter(key => item.ageRange[key] === true)),
    hairColor: flatten(Object.keys(item.hairColor).filter(key => item.hairColor[key] === true)),
    hairLength: flatten(Object.keys(item.hairLength).filter(key => item.hairLength[key] === true)),
    skinColor: flatten(Object.keys(item.skinColor).filter(key => item.skinColor[key] === true))
  }));

  useEffect(() => {
    const result = data.filter((deceased) => {
      const status = deceased.status ? "Approve" : "Disapprove";
      return (
        (deceased.firstName &&
          deceased.firstName.toLowerCase().match(search.toLowerCase())) ||
        Object.keys(deceased.gender).some(
          (key) =>
            deceased.gender[key] === true &&
            key.toLowerCase().match(search.toLowerCase())
        ) ||
        Object.keys(deceased.ageRange).some(
          (key) =>
            deceased.ageRange[key] === true &&
            key.toLowerCase().match(search.toLowerCase())
        ) ||
        (deceased.dateWhenBodyIsFound &&
          deceased.dateWhenBodyIsFound
            .toLowerCase()
            .match(search.toLocaleLowerCase())) ||
        (deceased.locationFound &&
          deceased.locationFound
            .toLowerCase()
            .match(search.toLocaleLowerCase())) ||
        (deceased.ethnicity &&
          deceased.ethnicity
            .toLowerCase()
            .match(search.toLocaleLowerCase())) ||
        status.toLowerCase().includes(search.toLowerCase())    
      );
    });

    setFilterUser(result);
  }, [search]);

  const columns = DeceasedColumn({
    // handleChangeStatus,
    handleDeceasedDelete,
    handleDeceasedView,
  });

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "30px",
        padding: "30px",
      }}
    >
      <BottomTabs />
      <ToastContainer />
      <DataTable
        title="Deceased List"
        columns={columns}
        data={filterUser}
        pagination
        highlightOnHover
        fixedHeader
        fixedHeaderScrollHeight="500px"
        selectableRows
        selectableRowsHighlight
        clearSelectedRows
        actions={
          <Button sx={{ fontSize: "12px" ,bgcolor: '#d3200a' ,color: 'red' ,'&:hover': { bgcolor: 'green' }}}>
            <CSVLink data={flattenedData} filename={`${fileName}.csv`} style={{color: 'white' ,textDecoration: 'unset' }}>
              Export
            </CSVLink>
          </Button>
        }
        subHeader
        subHeaderComponent={
          <input
            type="text"
            placeholder="Search"
            className="w-25 form-control"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        }
        subHeaderAlign="right"
      />
    </Box>
  );
};

export default DeceasedList;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Typography, TextField, Grid, Button, Container, Stack } from '@mui/material';
import PostAPI from '../../../utils/POSTAPI';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { useTranslation } from "react-i18next";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


const AddCategory = () => {
  const [name, setName] = useState('');
  const navigate = useNavigate();
  const [imgSrc, setImgSrc] = useState(null);
  const [filename, setFilename] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const { t } = useTranslation();
  const [displayType, setDisplayType] = React.useState(''); // Initialize with an empty string or the default value you want

  // To Preview Images
  const handleImage = async (e) => {
    e.preventDefault();
    let image = URL.createObjectURL(e.target.files[0]);

    setFilename(e.target.files[0].name);
    setImagePreview(image);
    setImgSrc(e.target.files[0]);
  };
  const handleSelectChange = (event) => {
    setDisplayType(event.target.value);
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();

    let formData = {
      name: name,
      displayType: displayType,
      photo: imgSrc,
    };
    
    PostAPI.AddCategory(formData, (response) => {
      if (!response.status) {
        alert("failed");
      } else if (response.status) {
        alert("Category Added Successfully");
        navigate(-1);
      } else {
        alert("something went wrong");
      }
    });
  };

  const handleChange = (e) => {
    setName(e.target.value);
  };

  return (
    <>
      <AppBar style={{ backgroundColor: "white" }} position="static">
        <Toolbar>
          <IconButton
            onClick={() => navigate(-1)}
            edge="start"
            color="inherit"
            aria-label="back"
          >
            <ArrowBackIcon style={{ color: "black" }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg">
        <Container maxWidth="lg">
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Typography
              color="#D22108"
              fontWeight="500"
              component="h5"
              variant="subtitle1"
              align="center"
              gutterBottom
              marginTop={2}
              marginBottom={1}
            >
              Category Name :-
            </Typography>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              value={name}
              onChange={handleChange}
            />
            </Grid>
            <Grid item xs={12} sm={12}>
            <Typography
              color="#D22108"
              fontWeight="500"
              component="h5"
              variant="subtitle1"
              align="center"
              gutterBottom
              marginTop={2}
              marginBottom={1}
            >
              Display on screen:
            </Typography>
            <Select
              fullWidth
              value={displayType}
              onChange={handleSelectChange}
              variant="outlined"
            >
              <MenuItem value="Home">Home/Filter</MenuItem>
              <MenuItem value="Danger">Danger report screen</MenuItem>
            </Select>
          </Grid>
          <Grid>
           {imagePreview ? (
          <div>
            <div style={{ position: "relative", margin: "10px 0" }}>
              <img alt={filename} src={imagePreview} width={"100%"} />

              <div
                style={{
                  position: "absolute",
                  top: "0",
                  zIndex: "3",
                  width: "100%",
                  height: "100%",
                }}
              >
                {/* <canvas ref={canvas}></canvas> */}
              </div>
            </div>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
              <Button
                variant="contained"
                component="label"
                startIcon={<PhotoCamera />}
              >
                {t("Reupload")}
                <input
                  hidden
                  accept="image/jpeg, image/png, image/webp"
                  type="file"
                  onChange={handleImage}
                />
              </Button>
            </Stack>
          </div>
        ) : (
          <div style={{ textAlign: "center", padding: "10px 0" }}>
            <Button
              id="upload-button"
              variant="contained"
              component="label"
              startIcon={<PhotoCamera />}
            >
              {t("Select")}
              <input
                name="upload"
                id="upload"
                hidden
                accept="image/jpeg, image/png, image/webp"
                type="file"
                onChange={handleImage}
              />
            </Button>
          </div>
        )}
          </Grid>
          
          <Grid item xs={12} sm={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: "#D22108",
                "&:hover": {
                  backgroundColor: "#D22108",
                  borderColor: "#D22108",
                },
              }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
      </Container>
      </Container>
    </>
  );
};

export default AddCategory;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Field from "../../components/Field/Field";
import { AppBar, Toolbar, IconButton, Typography, TextField, Grid, Button, Container } from '@mui/material';
import PostAPI from '../../utils/POSTAPI';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { emailValidation, passwordValidation } from "../../helpers/validators";
import { ToastContainer, toast } from "react-toastify";
import { LOGIN } from '../../routes/routeConfig';

export const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();


  const handleSubmit = () => {
    if (!emailValidation(email)) {
      alert("Please Enter A Valid Email");
    } else if (password == "") {
      alert("Please Enter A Password");
    } else if (!passwordValidation(password)) {
      alert("Password should be of atleast 8 characters");
    } else if (confirmPassword != password) {
      alert("confirmPassword not matched");
    } else {
      let formdata = {
        email,
        password,
      };
      PostAPI.SuperAdminResetPassword(formdata, (response) => {
        console.log("SuperAdminResetPassword",response);
        if (!response.status) {
          alert("Password Reset failed");
        } else if (response.status) {
          alert("Password Reset Successfully");
          navigate(`${LOGIN}`);
        } else {
          alert("Password Reset Failed");
        }
      });
    }
  };

  return (
    <Grid className="container">
     <ToastContainer /> 
    <AppBar style={{ backgroundColor: "white" }} position="static">
        <Toolbar>
        <IconButton
            onClick={() => navigate(-1)}
            edge="start"
            color="inherit"
            aria-label="back"
        >
            <ArrowBackIcon style={{ color: "black" }} />
        </IconButton>
        </Toolbar>
    </AppBar>
    <div className="seperator" />
        Reset Password
      <div className="seperator" />
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        noValidate
      >
        <Field
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          label="Email"
        />
        <Field
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          value={password}
          label="Password"
        />
        <Field
          onChange={(e) => setConfirmPassword(e.target.value)}
          value={confirmPassword}
          type="password"
          label="Confirm Password"
        />
        <div className="seperator" />
        <Button
          // disabled={email !== "" && password !== "" ? false : true}
          onClick={handleSubmit}
          style={{ width: "330px",background: "#d3200a" }}
          variant="contained"
          color="primary"
        >
          Save
        </Button>
      </form>
    </Grid>
  );
};

import React from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Button,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit, faEye } from "@fortawesome/free-solid-svg-icons";

export const UserColumn = ({ handleView, handleEdit, handleDelete }) => [
  {
    name: "No.",
    selector: (_, index) => index + 1,
    sortable: true,
  }, 
  {
    name: "ID",
    selector: row => row._id,
    sortable: true,
  },
  {
    name: "Name",
    selector: row => row.name,
    sortable: true,
  },
  {
    name: "Email",
    selector: row => row.email,
    sortable: true,
  },
  {
    name: "Request Count",
    cell: (row) => {
      const requestCount = row.requestCount && typeof row.requestCount.requestCount === "object"
        ? row.requestCount.requestCount
        : null;
  
      if (requestCount) {
        const countMap = {};
      
        // Count occurrences of each value
        Object.values(requestCount).forEach((value) => {
          if (countMap[value.helpType]) {
            countMap[value.helpType]++;
          } else {
            countMap[value.helpType] = 1;
          }
        });
  
        // Render the count for each value
        return (
          <ul>
            {Object.entries(countMap).map(([value, count]) => (
              <li key={value}>
                {value}: {count}
              </li>
            ))}
          </ul>
        );
      }
  
      return null;
    },
    sortable: true,
  },   
  {
    name: "Facebook Info",
    selector: row => row.facebookInfo && row ? row.facebookInfo : '',
    sortable: true,
  },
  {
    name: "Picture",
    cell: (row) => <img src={row.picture} alt="User Image" style={{ width: '50px', height: '50px' }} />
  },
  {
    name: "Status",
    cell: (row) => row.status ? <Typography variant="primary" >Active </Typography>: <Typography variant="primary" > Inactive </Typography> 
  },
  {
    name: "Type",
    selector: row => row.loginType,
  },
  {
    name: "Updated At",
    selector: row => row.updatedAt,
    sortable: true,
  },
  {
    name: "User Name",
    selector: row => row.userName,
    sortable: true,
  },
  {
    name: "Validated Beneficiary",
    cell: (row) => row.reason ? <Typography variant="primary" >{row.reason} </Typography>: <Typography variant="primary" > Not validated </Typography>
  },
  {
    name: "Actions",
    sortable: false,
    cell: (row) => (
      <div style={{
        display: "flex",
      }}>
        <Button
          variant="danger"
          data-tag="allowRowEvents"
          data-action="delete"
          onClick={() => handleDelete(row._id)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
        <Button variant="primary" onClick={() => handleEdit(row._id)}>
          <FontAwesomeIcon icon={faEdit} />
        </Button>
        <Button variant="primary" onClick={() => handleView(row._id)}>
          <FontAwesomeIcon icon={faEye} />
        </Button>
      </div>
    ),
  },
];
